import loadable from '@loadable/component';

const OrderTemplates = loadable(() => import(/*webpackChunkName:"order-tmpl"*/'./List'));

const OrderTemplateCreateForm = loadable(() => import(/*webpackChunkName:"order-tmpl"*/'./OrderTemplateCreateForm'));
const OrderTemplateEditForm = loadable(() => import(/*webpackChunkName:"order-tmpl"*/'./OrderTemplateEditForm'));

/* eslint-disable react/no-multi-comp */
export function orderTemplatesRenderer(_page) {
  return <OrderTemplates />;
}

export function createOrderTemplateRenderer(_page) {
  return <OrderTemplateCreateForm isEdit={false} />;
}

export function editOrderTemplateRenderer(_page) {
  return <OrderTemplateEditForm isEdit />;
}
