//3.7. Approve, or decline price change requests and view all price change requests
import { priceRequestDetailsPageQuery } from './queries';
import { map } from 'rxjs/operators';
import { PageComponentNames } from '../componentNames';
import { initSystemPageContent, initPageContent } from '../system';
import { loadSystemPageQuery } from '../system/queries';

export default ({ params, options: pageOptions }, state$, { api }) => {

  if (params?.previewToken) {
    return api.graphApi(loadSystemPageQuery('priceRequestDetails')).pipe(
      map(({ pages: { priceRequest: page } }) => {
        if (!page)
          return null;

        return {
          page: { ...page, component: PageComponentNames.PriceRequestDetails },
        };
      }),
      initSystemPageContent(),
    );
  }
  const requestNo = params.requestNo;

  return api.graphApi(priceRequestDetailsPageQuery, { requestNo }).pipe(
    map(({ pages: { priceRequest: page }, priceRequestById }) => {
      if (!page || !priceRequestById)
        return null;

      const initializedPage = initPageContent(page);

      const resultPage = {
        component: PageComponentNames.PriceRequestDetails,
        priceRequest: priceRequestById,
        ...initializedPage,
      };

      return { page: resultPage };
    }),
  );
};
