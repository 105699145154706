function isValidString(value?: string): value is string {
  return typeof value === 'string' && /^\d{4}-\d{2}-\d{2}(T00:00:00(\.0+)?Z)?$/.test(value);
}

function today(): Date {
  const date = new Date();
  date.setHours(0, 0, 0, 0);
  return date;
}

function parse(value?: string): Date | null {
  if (!isValidString(value))
    return null;

  const year = +value.substring(0, 4);
  const month = +value.substring(5, 7);
  const day = +value.substring(8, 10);
  return new Date(year, month - 1, day);
}

function toISOString(date?: Date): string | null {
  if (!date)
    return null;

  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return year + '-' + (month < 10 ? '0' + month : month) + '-' + (day < 10 ? '0' + day : day) + 'T00:00:00Z';
}

function compare(a?: Date | string, b?: Date | string): number | undefined {
  const d1 = a instanceof Date ? a : parse(a);
  const d2 = b instanceof Date ? b : parse(b);
  if (!d1 || !d2)
    return undefined;

  let result = _compareNumbers(d1.getFullYear(), d2.getFullYear());
  if (result === 0)
    result = _compareNumbers(d1.getMonth(), d2.getMonth());
  if (result === 0)
    result = _compareNumbers(d1.getDate(), d2.getDate());
  return result;
}

function _compareNumbers(a: number, b: number): number {
  return a < b ? -1 : a > b ? 1 : 0;
}

//3.5. Submit price change requests
function getLastDayOfYear(year: number): Date {
  const date = new Date(year, 11, 31);
  date.setHours(0, 0, 0, 0);
  return date;
}

export default { isValidString, today, parse, toISOString, compare, getLastDayOfYear };