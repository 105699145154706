import{AbilityTo}from'behavior/user/constants';var skipBasketField='basket@skip(if:true){id}';export var clearNonOrderablesMutation=" mutation ClearNonOrderables{ basket{clearNonOrderables{".concat(skipBasketField,"}} }");export var pageSize=10;export var serviceLineFragment=" fragment serviceLine on ServiceLine{ id title price quantity subTotal }";//3.1. One-time price change and place direct orders
//3.5. Submit price change requests
//Ticket 192779: [Changes to ticket 182264] - Show the stock level, plant details and price type in the shopping basket - Add Special Price and Promo Price columns
var productLineBaseInfo=" id discount price isOneTimeCustomPriceRequested customPrice oldPrice listPrice specialPrice promoPrice quantity subTotal validityFromDate validityToDate salesAgreementLineId plant productStock priceType priceGroupName availableSalesAgreementLines{ id } uom{ id description@requireAbility(ability:".concat(AbilityTo.ViewUnitOfMeasure,") } serviceLines{ ...serviceLine } extendedTexts{ title }");var salesAgreementInfoFieldSegment=" salesAgreementInfo{ id isAppliedToLines }";// 3.4.Show current credit limit and status of a customer
//3.5. Submit price change requests
export var basketFieldBase=" id editDocumentId editDocumentType isPriceRequestPage isGroupPrice priceChangeRequestComment priceRequestNo modifiedDate subTotal totalPrice totalPriceExcludingTax prepayment roundOff isBlocked tax{ amount taxes{amount description} } discount{ invoice payment promotion{ amount code title } } paymentCost{price} shippingCost{price} serviceLines{ ...serviceLine } nonOrderableLines{ description reason } creditLimit{ validationResult exceededAmount } balanceInfo{ balance creditLimit } minimumOrderValue{ validationResult minimumOrderAmount } ".concat(salesAgreementInfoFieldSegment," ");export var basketLinesFragment=" fragment basketLines on BasketLinesCollection{ totalCount list{ ".concat(productLineBaseInfo," ...on ProductLine{ subLines{ ").concat(productLineBaseInfo," title variationId } isSupplementary product{ id title url image{small} images:media(type:IMAGE){ ...on ProductImage{small variantId} } uoms{ id description@requireAbility(ability:").concat(AbilityTo.ViewUnitOfMeasure,") maximumQuantity minimumQuantity quantityStep } categoriesPaths@include(if:$loadCategories){ categories{ name } } } } ...on ConfigurationResultLine{ configuration{ id masterProduct{ id title categoriesPaths@include(if:$loadCategories){ categories{ name } } } products{ id title variantId variantTitle url description image{small} categoriesPaths@include(if:$loadCategories){ categories{ name } } } } } } } ");//3.11. Option to search products in the shopping basket
var basketFieldSegment=" ".concat(basketFieldBase," totalCount @skip(if:$countSubLines) productLines(options:{page:{index:$index,size:").concat(pageSize,",countSubLines:$countSubLines},keyword:$keyword}){ ...basketLines } ");export var maxLinesCount=100;//3.5. Submit price change requests
export var modifiedLinesFields=" list{ price quantity uom{ id } validityFromDate validityToDate ...on ProductLine{ subLines{ price quantity uom{ id } variationId } isSupplementary product{ id title categoriesPaths{ categories{ name } } } } ...on ConfigurationResultLine{ configuration{ id masterProduct{ id title categoriesPaths{ categories{ name } } } products{ id title variantId categoriesPaths{ categories{ name } } } } } }";export var addProductsMutation=" mutation AddProducts($lines:[BasketLineInput!]!,$addedLinesCount:Int!,$requestModifiedLines:Boolean!){ basket{ addProducts(lines:$lines){ modifiedLines(options:{page:{size:$addedLinesCount,countSubLines:false},sorting:RECENT})@include(if:$requestModifiedLines){ ".concat(modifiedLinesFields," } } } }");export var applySalesAgreementAndAddProductsMutation=" mutation ApplyAgreementAndAddProducts($lines:[BasketLineInput!]!,$addedLinesCount:Int!,$requestModifiedLines:Boolean!,$agreementId:String!){ salesAgreement{ apply(agreementId:$agreementId){ success } } basket{ addProducts(lines:$lines){ modifiedLines(options:{page:{size:$addedLinesCount,countSubLines:false},sorting:RECENT})@include(if:$requestModifiedLines){ ".concat(modifiedLinesFields," } } } }");export var salesAgreementQuery=" query SalesAgreement($agreementId:String!,$productIds:[ID!]!){ salesAgreements{ agreement(agreementId:$agreementId){ lines(productIds:$productIds){ id discountPercent price@requireAbility(ability:".concat(AbilityTo.ViewPrices,") isMaxEnforced uom{ id description } quantities{ commitment remaining } amounts{ remaining } location{ code title } } } } }");//3.11. Option to search products in the shopping basket
export var basketDetailsQuery=" query BasketDetails($index:Int=0,$countSubLines:Boolean!,$loadCategories:Boolean=false,$keyword:String=null){ basket{ isAvailable ".concat(basketFieldSegment," } }")+serviceLineFragment+basketLinesFragment;/**
 * Mutation to save modified basket lines and retrieve updated basket.
 **/ //3.11. Option to search products in the shopping basket
var modifyLinesMutation=" mutation UpdateAndGetBasket($data:BasketUpdateInput!,$countSubLines:Boolean!,$index:Int=0,$requestModifiedLines:Boolean!,$loadCategories:Boolean=false,$keyword:String=null){ basket{ update(data:$data){ basket{".concat(basketFieldSegment,"} modifiedLines(options:{page:{size:").concat(pageSize,",countSubLines:false},sorting:RECENT})@include(if:$requestModifiedLines){ ").concat(modifiedLinesFields," } } } }")+serviceLineFragment+basketLinesFragment;/**
 * Mutation to save modified basket lines without basket retrieval.
 **/ //3.9. Save price change requests
export var saveLinesOnlyMutation=" mutation UpdateLines($data:BasketUpdateInput!,$requestModifiedLines:Boolean!){ basket{ update(data:$data){ modifiedLines(options:{page:{size:".concat(pageSize,",countSubLines:false},sorting:RECENT})@include(if:$requestModifiedLines){ ").concat(modifiedLinesFields," } priceRequestNo } } }");var addCouponMutation=" mutation AddCoupon($code:String!,$countSubLines:Boolean!,$index:Int=0,$loadCategories:Boolean=false,$keyword:String=null){ basket{ addCoupon(code:$code){ basket{".concat(basketFieldSegment,"} } } }")+serviceLineFragment+basketLinesFragment;var modifyAndAddCouponMutation=" mutation UpdateAndAddCoupon($data:BasketUpdateInput!,$code:String!,$countSubLines:Boolean!,$index:Int=0,$requestModifiedLines:Boolean!,$loadCategories:Boolean=false){ basket{ update(data:$data){ modifiedLines(options:{page:{size:".concat(pageSize,",countSubLines:false},sorting:RECENT})@include(if:$requestModifiedLines){ ").concat(modifiedLinesFields," } } addCoupon(code:$code){ basket{").concat(basketFieldSegment,"} } } }")+serviceLineFragment+basketLinesFragment;//3.5. Submit price change requests
export var changeBasketTypeMutation=" mutation ChangeBasketType($countSubLines:Boolean!,$index:Int=0,$loadCategories:Boolean=false,$basketType:Boolean!,$keyword:String=null){ basket{ updateBasketType(basketType:$basketType){ basket{".concat(basketFieldSegment,"} } } }")+serviceLineFragment+basketLinesFragment;export function getUpdateQuery(variables){//3.5.Submit price change requests
if(variables.basketType!=null)return changeBasketTypeMutation;if(variables.data!=null&&variables.code!=null)return modifyAndAddCouponMutation;else if(variables.data)return modifyLinesMutation;else if(variables.code!=null)return addCouponMutation;return;}export var clearBasketMutation=" mutation ClearBasketLines($requestModifiedLines:Boolean!){ basket{ empty{ modifiedLines(options:{page:{size:".concat(maxLinesCount,",countSubLines:false},sorting:RECENT})@include(if:$requestModifiedLines){ ").concat(modifiedLinesFields," } } } }");export var deleteBasketMutation=" mutation DeleteBasket{ basket{delete{".concat(skipBasketField,"}} }");export var basketSummaryQuery=" query BasketSummary($sorting:BasketLinesSorting!,$calculated:Boolean!,$loadCategories:Boolean=false,$loadUoms:Boolean=false){ basket(calculated:$calculated){ modifiedDate totalPrice@include(if:$calculated) totalPriceExcludingTax@include(if:$calculated) editDocumentType isAvailable isBlocked totalCount productLines(options:{page:{index:0,size:3,countSubLines:false},sorting:$sorting,groupByProduct:false}){ list{ price@include(if:$calculated) quantity uom@include(if:$loadUoms){ id description } ...on ProductLine{ subLines{ title variationId } product{ id title price url image{small} images:media(type:IMAGE){ ...on ProductImage{small variantId} } uoms@include(if:$loadUoms){ id description } categoriesPaths@include(if:$loadCategories){ categories{ name } } } } ...on ConfigurationResultLine{ configuration{ products{ id title variantTitle url description image{small} categoriesPaths@include(if:$loadCategories){ categories{ name } } } } } } } ".concat(salesAgreementInfoFieldSegment," } }");//3.5. Submit price change requests
export var savePriceRequestMutation=" mutation SavePriceRequest($isSavePriceRequestAsDraft:Boolean!){ basket{ createPriceRequest(isSavePriceRequestAsDraft:$isSavePriceRequestAsDraft){ priceRequestNo } } }";