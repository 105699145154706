//3.3. Centralized purchasing setup
import type { CustomerCollection } from './types';

export const CUSTOMERS_REQUESTED = 'REPRESENTATION/STORES/REQUESTED' as const;
export const requestStores = (keywords = '', index = 0, size = 10) => ({
  type: CUSTOMERS_REQUESTED,
  payload: { keywords, page: { index, size } },
});

export const CUSTOMERS_RECEIVED = 'REPRESENTATION/STORES/RECEIVED' as const;
export const storesLoaded = (customers: CustomerCollection, keywords: string) => ({
  type: CUSTOMERS_RECEIVED,
  payload: { customers, keywords },
});

export type RepresentationAction = ReturnType<
  | typeof requestStores
  | typeof storesLoaded
>;
