//3.7. Approve, or decline price change requests and view all price change requests
import { combineEpics, ofType } from 'redux-observable';
import { setLoadingIndicator, unsetLoadingIndicator } from 'behavior/loadingIndicator';
import { of } from 'rxjs';
import { mergeMap, switchMap, startWith, pluck } from 'rxjs/operators';
import { catchApiErrorWithToast, retryWithToast } from 'behavior/errorHandling';
import {
  WITHDRAW_PRICE_REQUEST,
  PRICE_REQUEST_REMOVE_LINE,
  REJECT_PRICE_REQUEST,
  APPROVE_PRICE_REQUEST,
  PRICE_REQUEST_LINES_UPDATE,
  DELETE_PRICE_REQUEST,
  EDIT_PRICE_REQUEST,
} from './actions';
import {
  withdrawPriceRequestMutation,
  removeLinefromPriceRequestMutation,
  rejectPriceRequestMutation,
  approvePriceRequestMutation,
  updateLinesForPriceRequestMutation,
  deletePriceRequestMutation,
  editPriceRequestMutation,
} from './queries';
import { toasts } from 'behavior/toasts';
import { routesBuilder } from 'routes';
import { basketChangeCompleted, navigateTo } from 'behavior/events';

const withdrawPriceRequesEpic = (action$, state$, { api, logger }) => {
  return action$.pipe(
    ofType(WITHDRAW_PRICE_REQUEST),
    pluck('payload'),
    switchMap(({ requestNo }) => api.graphApi(withdrawPriceRequestMutation, { requestNo }).pipe(
      mergeMap(() => {

        toasts.success('', { textKey: 'PriceRequest_Withdrawn' });
        return of(unsetLoadingIndicator(), navigateTo(routesBuilder.forPriceRequests()));
      }),
      catchApiErrorWithToast(['INVALID_INPUT'], of(unsetLoadingIndicator())),
      retryWithToast(action$, logger, () => of(unsetLoadingIndicator())),
      startWith(setLoadingIndicator()),
    )),
  );
};

const rejectPriceRequesEpic = (action$, state$, { api, logger }) => {
  return action$.pipe(
    ofType(REJECT_PRICE_REQUEST),
    pluck('payload'),
    switchMap(({ requestNo, isManager, addedComment }) => api.graphApi(rejectPriceRequestMutation, { requestNo, isManager, addedComment }).pipe(
      mergeMap(() => {

        toasts.success('', { textKey: 'PriceRequest_Rejected' });
        return of(unsetLoadingIndicator(), navigateTo(routesBuilder.forPriceRequests()));
      }),
      catchApiErrorWithToast(['INVALID_INPUT'], of(unsetLoadingIndicator())),
      retryWithToast(action$, logger, () => of(unsetLoadingIndicator())),
      startWith(setLoadingIndicator()),
    )),
  );
};

const approvePriceRequesEpic = (action$, state$, { api, logger }) => {
  return action$.pipe(
    ofType(APPROVE_PRICE_REQUEST),
    pluck('payload'),
    switchMap(({ requestNo, addedComment, isManager, modified }) => api.graphApi(approvePriceRequestMutation, { data: { requestNo, addedComment, isManager, modifiedLines: { modified } } }).pipe(
      mergeMap(() => {

        toasts.success('', { textKey: 'PriceRequest_Approved' });
        return of(unsetLoadingIndicator(), navigateTo(routesBuilder.forPriceRequests()));
      }),
      catchApiErrorWithToast(['INVALID_INPUT'], of(unsetLoadingIndicator())),
      retryWithToast(action$, logger, () => of(unsetLoadingIndicator())),
      startWith(setLoadingIndicator()),
    )),
  );
};

const removeLineFromPriceRequestEpic = (action$, state$, { api, logger }) => {
  return action$.pipe(
    ofType(PRICE_REQUEST_REMOVE_LINE),
    pluck('payload'),
    switchMap(({ requestNo, productId, unitOfMeasureId }) => api.graphApi(removeLinefromPriceRequestMutation, { input: { requestNo, productId, unitOfMeasureId } }).pipe(
      mergeMap(() => {

        toasts.success('', { textKey: 'PriceRequestLine_Deleted' });
        return of(unsetLoadingIndicator(), navigateTo(routesBuilder.forPriceRequest(requestNo)));
      }),
      catchApiErrorWithToast(['INVALID_INPUT'], of(unsetLoadingIndicator())),
      retryWithToast(action$, logger, () => of(unsetLoadingIndicator())),
      startWith(setLoadingIndicator()),
    )),
  );
};

const updateLinesOfPriceRequestEpic = (action$, state$, { api, logger }) => {
  return action$.pipe(
    ofType(PRICE_REQUEST_LINES_UPDATE),
    pluck('payload'),
    switchMap(({ requestNo, modified }) => api.graphApi(updateLinesForPriceRequestMutation, { requestNo, data: { modified } }).pipe(
      mergeMap(() => {

        toasts.success('', { textKey: 'PriceRequestLine_Updated' });
        return of(unsetLoadingIndicator(), navigateTo(routesBuilder.forPriceRequest(requestNo)));
      }),
      catchApiErrorWithToast(['INVALID_INPUT'], of(unsetLoadingIndicator())),
      retryWithToast(action$, logger, () => of(unsetLoadingIndicator())),
      startWith(setLoadingIndicator()),
    )),
  );
};

//3.9. Save price change requests
const deletePriceRequestEpic = (action$, state$, { api, logger }) => {
  return action$.pipe(
    ofType(DELETE_PRICE_REQUEST),
    pluck('payload'),
    switchMap(({ requestNo }) => api.graphApi(deletePriceRequestMutation, { requestNo }).pipe(
      mergeMap(() => {

        toasts.success('', { textKey: 'PriceRequest_Deleted' });
        return of(unsetLoadingIndicator(), navigateTo(routesBuilder.forPriceRequests()));
      }),
      catchApiErrorWithToast(['INVALID_INPUT'], of(unsetLoadingIndicator())),
      retryWithToast(action$, logger, () => of(unsetLoadingIndicator())),
      startWith(setLoadingIndicator()),
    )),
  );
};

//3.9. Save price change requests
const editPriceRequestEpic = (action$, state$, { api, logger }) => {
  return action$.pipe(
    ofType(EDIT_PRICE_REQUEST),
    pluck('payload'),
    switchMap(({ requestNo }) => api.graphApi(editPriceRequestMutation, { requestNo }).pipe(
      mergeMap(() => {
        return of(unsetLoadingIndicator(), basketChangeCompleted(1), navigateTo(routesBuilder.forBasket()));
      }),
      catchApiErrorWithToast(['INVALID_INPUT'], of(unsetLoadingIndicator())),
      retryWithToast(action$, logger, () => of(unsetLoadingIndicator())),
      startWith(setLoadingIndicator()),
    )),
  );
};

export default combineEpics(
  withdrawPriceRequesEpic,
  rejectPriceRequesEpic,
  approvePriceRequesEpic,
  removeLineFromPriceRequestEpic,
  updateLinesOfPriceRequestEpic,
  deletePriceRequestEpic,
  editPriceRequestEpic,
);