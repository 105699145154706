//3.7. Approve, or decline price change requests and view all price change requests
import { priceRequestsPageQuery } from './queries';
import { map } from 'rxjs/operators';
import { PageComponentNames } from '../componentNames';
import { initSystemPageContent, initPageContent } from '../system';
import DateOnly from 'date-only';
import { loadSystemPageQuery } from '../system/queries';
import { PriceRequestStatus } from 'behavior/pages/checkout';
import { AbilityTo, AbilityState } from 'behavior/user/constants';

export default ({ params, options: pageOptions }, state$, { api }) => {

  const filter = normalizeFilter(params && params.filter, state$);

  if (params?.previewToken) {
    return api.graphApi(loadSystemPageQuery('priceRequests')).pipe(
      map(({ pages: { priceRequests: page } }) => {
        if (!page)
          return null;

        return {
          page: { ...page, component: PageComponentNames.PriceRequests },
        };
      }),
      initSystemPageContent(),
    );
  }

  const options = { ...filter };

  return api.graphApi(priceRequestsPageQuery, { options }).pipe(
    map(({ pages: { priceRequests: page }, priceRequests }) => {
      if (!page)
        return null;

      const initializedPage = initPageContent(page);

      const resultPage = {
        component: PageComponentNames.PriceRequests,
        priceRequests,
        filter,
        notify: params?.notify,
        ...initializedPage,
      };

      return { page: resultPage };
    }),
  );
};

export function normalizeFilter(filter, state) {
  if (filter && filter.priceRequestDate)
    return filter;

  const from = new Date();
  let fromDate = from.getDate();
  const fromMonth = from.getMonth() - 3;
  const fromMonthLastDay = getNumberOfDaysInMonth(fromMonth + 1, from.getFullYear());
  if (fromMonthLastDay < fromDate)
    fromDate = fromMonthLastDay;

  from.setMonth(fromMonth, fromDate);

  let status;
  const abilities = state.value.user.abilities;
  const canApprovePriceChangeRequestsAbility = abilities[AbilityTo.CanApprovePriceChangeRequests];
  const canPlacePriceChangeRequestsAbility = abilities[AbilityTo.CanPlacePriceChangeRequests];

  const canApprovePriceChangeRequests = typeof canApprovePriceChangeRequestsAbility !== 'undefined' && canApprovePriceChangeRequestsAbility === AbilityState.Available;
  const canPlacePriceChangeRequests = typeof canPlacePriceChangeRequestsAbility !== 'undefined' && canPlacePriceChangeRequestsAbility === AbilityState.Available;
  const pinnaclePricingTeamMember = state.value.user.pinnaclePricingTeamMember;

  if (pinnaclePricingTeamMember) {
    status = PriceRequestStatus.PendingPinnaclePricingApproval;
  }
  else {
    if ((canPlacePriceChangeRequests && !canApprovePriceChangeRequests) || (canPlacePriceChangeRequests && canApprovePriceChangeRequests)) {
      status = PriceRequestStatus.PendingManagerApproval; // This was changed with the comment in Ticket 192789 from customer. Prviously default value is the Draft
    }
    else if (!canPlacePriceChangeRequests && canApprovePriceChangeRequests) {
      status = PriceRequestStatus.PendingManagerApproval;
    }
  }

  return { ...filter, priceRequestDate: { from: DateOnly.toISOString(from) }, status };
}

function getNumberOfDaysInMonth(month, year) {
  return new Date(year, month, 0).getDate();
}