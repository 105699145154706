import type { Handler } from '../types';
import type { OrderTemplate } from './types';
import { orderTemplatesPageQuery } from './queries';
import { map } from 'rxjs/operators';
import { of } from 'rxjs';
import { PageComponentNames } from '../componentNames';
import { initSystemPageContent, SystemPage, SystemPageData } from '../system';
import { orderTemplatesReceived } from './actions';
import { loadSystemPageQuery } from '../system/queries';
import { generateKey } from 'utils/helpers';
import { RouteName } from 'routes';

const handler: Handler<OrderTemplatesRouteData, OrderTemplatesPage> = ({ params }, _state$, { api }) => {
  if (params?.previewToken) {
    return api.graphApi<SystemPageResponse>(loadSystemPageQuery('orderTemplates')).pipe(
      map(({ pages: { orderTemplates: page } }) => {
        if (!page)
          return null;

        const orderTemplates = Array.from(Array(5)).map(() => ({ id: generateKey(), name: null, description: '', lines: [], createdDate: '', modifiedDate: '' }));

        return {
          page: { ...page, component: PageComponentNames.OrderTemplates as const },
          action$: of(orderTemplatesReceived({ orderTemplates })),
        };
      }),
      initSystemPageContent(),
    );
  }

  return api.graphApi<OrderTemplatesPageResponse>(orderTemplatesPageQuery).pipe(
    map(({ pages: { orderTemplates: page }, orderTemplates }) => {
      if (!page)
        return null;

      return {
        page: { ...page, component: PageComponentNames.OrderTemplates as const },
        action$: of(orderTemplatesReceived({ orderTemplates })),
      };
    }),
    initSystemPageContent(),
  );
};

export default handler;

type OrderTemplatesPage = SystemPage & {
  component: PageComponentNames.OrderTemplates;
};

type OrderTemplatesRouteData = {
  routeName: RouteName.OrderTemplates;
  params?: { previewToken?: string };
};

type SystemPageResponse = {
  pages: {
    orderTemplates: SystemPageData;
  };
};

type OrderTemplatesPageResponse = SystemPageResponse & { orderTemplates: OrderTemplate[] };
