import PropTypes from 'prop-types';
import { Breadcrumb as BreadcrumbPrimitive } from 'components/primitives/breadcrumb';
import { routesBuilder } from 'routes';
import { useSimpleTexts } from 'components/sanaText';
import { useIsMobileViewport } from 'utils/hooks';

const BasketBreadcrumb = ({ isPriceRequestPage }) => {
  const isMobile = useIsMobileViewport();
  const [shoppingBasketHeaderText] = useSimpleTexts([!isMobile && (isPriceRequestPage ? 'PriceRequest_Header' : 'ShoppingBasket_Header')]).texts;

  if (isMobile)
    return null;

  const basketNode = {
    title: shoppingBasketHeaderText || '',
    link: { to: routesBuilder.forBasket() },
  };

  return <BreadcrumbPrimitive items={[basketNode]} />;
};

BasketBreadcrumb.propTypes = {
  isPriceRequestPage: PropTypes.bool,
};

export default BasketBreadcrumb;
