import styles from '../AccountMenu.module.scss';
import PropTypes from 'prop-types';
import { SimpleText } from 'components/sanaText';
import { WishlistLink, MyAccountLink, Link } from 'components/primitives/links';
import { useSelector, shallowEqual } from 'react-redux';
import LogoutButton from './LogoutButton';
import { ShopAccountType } from 'behavior/user';
import { routesBuilder } from 'routes';
import { useCurrentRouteAsBackTo } from 'utils/hooks';

const CustomerMenuItems = ({ logoutDelay }) => {

  //3.3. Centralized purchasing setup
  const { name, shopAccountType, allowMultipleAccounts } = useSelector(({ user }) => ({
    name: user.name,
    shopAccountType: user.shopAccountType,
    allowMultipleAccounts: user.allowMultipleAccounts,
  }), shallowEqual);

  const backTo = useCurrentRouteAsBackTo();

  return (
    <>
      <div className={styles.bodyHeader}>
        <span className="user-name"><SimpleText textKey="Login_Welcome" /> <b>{name}</b></span>
      </div>

      {/*3.3. Centralized purchasing setup*/}
      {(shopAccountType === ShopAccountType.Customer) && allowMultipleAccounts &&
        (<Link to={routesBuilder.forRepresentStore} options={{ backTo }} className={styles.item}>
          <SimpleText textKey="ButtonText_RepresentStore" />
        </Link>
      )}

      <MyAccountLink className={styles.item} />
      <WishlistLink className={styles.item} />
      <LogoutButton logoutDelay={logoutDelay} />
    </>
  );
};

CustomerMenuItems.propTypes = {
  logoutDelay: PropTypes.number,
};

export default CustomerMenuItems;
