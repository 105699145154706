import type { Action } from 'redux';
import type { AppState } from 'behavior';
import type { Epic } from 'behavior/types';
import type { ModifiedLines } from 'behavior/basket/types';
import type { OrderTemplate, OrderTemplateLine, Product, SaveOrderTemplateResult } from './types';
import {
  orderTemplatesQuery,
  orderTemplateLinesQuery,
  addToBasketMutation,
  removeTemplatesMutation,
  productsQuery,
  saveOrderTemplateMutation,
  lineAddToBasketMutation,
  removeProductFromOrderTemplateMutation,
  deleteAllBasketLinesMutation,
  pastOrdersQuery,
  editOrderTemplateMutation,
  editOrderTemplateUpdateLinesMutation,
  searchOrderTemplateLinesQuery,
  addNewLineToEditTemplateMutation,
  lineAddToBasketFromEditTemplateMutation,
} from './queries';
import {
  ORDER_TEMPLATES_REQUESTED,
  ORDER_TEMPLATE_LINES_REQUESTED,
  ORDER_TEMPLATES_ADDING_REQUESTED,
  ORDER_TEMPLATES_REMOVAL_REQUESTED,
  orderTemplatesReceived,
  orderTemplateLinesReceived,
  orderTemplatesAdded,
  orderTemplatesRemoved,
  OrderTemplateAction,
  ORDER_TEMPLATE_PRODUCTS_REQUESTED,
  receiveProducts,
  SaveOrderTemplateAction,
  ORDER_TEMPLATE_CREATED,
  orderTemplateReceivedErrorMessage,
  ORDER_TEMPLATE_BASKET_LINE_LIST_ADDING_REQUESTED,
  AddLinesToBasketTemplateAction,
  ORDER_TEMPLATE_BASKET_CLEAR_ALL_LINES,
  ClearAllBasketLines,
  ORDER_TEMPLATE_DELETE_BASKET_LINE,
  DeleteBasketLine,
  PAST_ORDERS_REQUESTED,
  receivePastOrders,
  PastOrderRequestAction,
  ORDER_TEMPLATE_EDITED,
  orderTemplateReset,
  ORDER_TEMPLATE_EDIT_UPDATE_LINES,
  EditOrderTemplateUpdateLinesAction,
  editOrderTemplateLinesUpdated,
  ORDER_TEMPLATE_EDIT_LINES_SEARCH,
  EditOrderTemplateLinesSearchAction,
  editOrderTemplateLinesSearchReceived,
  ORDER_TEMPLATE_EDIT_ADD_NEW_LINE,
  AddNewLineToTemplateAction,
  editOrderTemplateLinesSearch,
  EDIT_ORDER_TEMPLATE_LINES_ADDING_TO_BASKET,
  AddLinesToBasketFromEditTemplateAction,
} from './actions';
import { createApiCallEpic } from '../helpers';
import { combineEpics, ofType } from 'redux-observable';
import { LOCATION_CHANGED } from 'behavior/events';
import { of, from } from 'rxjs';
import { mergeMap, map, takeUntil, switchMap, startWith, pluck } from 'rxjs/operators';
import { concatToIfEmpty } from 'utils/rxjs';
import { basketChangeStarted, basketChangeCompleted, navigateTo } from 'behavior/events';
import { routesBuilder } from 'routes';
import { trackAddToBasket, getProductsTrackingDataFromLines } from 'behavior/analytics';
import { LoadedSettings } from 'behavior/settings';
import { catchApiErrorWithToast, retryWithToast } from 'behavior/errorHandling';
import { unlockForm, FormName } from 'behavior/pages';
import { setLoadingIndicator, unsetLoadingIndicator } from 'behavior/loadingIndicator';
import { toasts } from 'behavior/toasts';
import { defaultPageSize } from './queries';

type OrderTemplatesResponse = {
  orderTemplates: OrderTemplate[] | null;
};

const loadOrderTemplatesEpic = createApiCallEpic<OrderTemplateAction, OrderTemplatesResponse>(
  ORDER_TEMPLATES_REQUESTED,
  orderTemplatesQuery,
  orderTemplatesReceived,
);

type OrderTemplateLinesResponse = {
  orderTemplates: { lines: { list: OrderTemplateLine[] } }[] | null;
};

const loadOrderTemplateLinesEpic: Epic<OrderTemplateAction> = (action$, state$, { api, logger }) => {
  const locationChanged$ = action$.pipe(ofType(LOCATION_CHANGED));

  return action$.pipe(
    ofType(ORDER_TEMPLATE_LINES_REQUESTED),
    mergeMap(action => api.graphApi<OrderTemplateLinesResponse>(orderTemplateLinesQuery, {
      ...action.payload,
      loadCategories: state$.value.analytics?.isTrackingEnabled,
    }).pipe(
      map(({ orderTemplates }) =>
        orderTemplateLinesReceived(action.payload.id,
          orderTemplates &&
          orderTemplates[0] &&
          orderTemplates[0].lines?.list?.filter(l => l.product.exists || l.hasConfiguration),
        )),
      retryWithToast(action$, logger),
      takeUntil(locationChanged$),
    ),
    ),
  );
};

type AddToBasketResponse = {
  orderTemplates: {
    addToBasket: {
      templatesAmount: number;
      linesAmount: number;
      modifiedLines: ModifiedLines;
    } | null;
  } | null;
};

const addToBasketEpic: Epic<OrderTemplateAction> = (action$, state$, { api, logger }) => {
  const locationChanged$ = action$.pipe(ofType(LOCATION_CHANGED));
  return action$.pipe(
    ofType(ORDER_TEMPLATES_ADDING_REQUESTED),
    switchMap(action => api.graphApi<AddToBasketResponse>(addToBasketMutation, { ...action.payload, requestModifiedLines: isTrackingEnabled(state$.value) }).pipe(
      mergeMap(({ orderTemplates }) => {
        if (!orderTemplates || !orderTemplates.addToBasket)
          return of(orderTemplatesAdded(0), basketChangeCompleted(0));

        const { templatesAmount, linesAmount, modifiedLines } = orderTemplates.addToBasket;
        const actions: Array<Action> = [orderTemplatesAdded(templatesAmount), basketChangeCompleted(linesAmount)];

        const addedProducts = modifiedLines ? getProductsTrackingDataFromLines(modifiedLines.list) : [];
        if (addedProducts && addedProducts.length) {
          actions.push(trackAddToBasket({ products: addedProducts }));
        }

        const backTo = state$.value.page.backTo;
        if (backTo) {
          actions.push(navigateTo(backTo.routeData, backTo.url));
        } else if ((state$.value.settings as LoadedSettings).basket.redirectOnAdd) {
          actions.push(navigateTo(routesBuilder.forBasket()));
        }

        return from(actions);
      }),
      retryWithToast(action$, logger),
      concatToIfEmpty(of(orderTemplatesAdded(0), basketChangeCompleted(0))),
      takeUntil(locationChanged$),
      startWith(basketChangeStarted()),
    )),
  );
};

const orderTemplatesRemoveEpic: Epic<OrderTemplateAction> = (action$, _state$, { api, logger }) => {
  const locationChanged$ = action$.pipe(ofType(LOCATION_CHANGED));

  return action$.pipe(
    ofType(ORDER_TEMPLATES_REMOVAL_REQUESTED),
    mergeMap(action => api.graphApi<unknown>(removeTemplatesMutation, action.payload).pipe(
      mergeMap(() => {
        toasts.success('', { textKey: 'OrderTemplate_Deleted' });
        return of(navigateTo(routesBuilder.forOrderTemplates()), orderTemplatesRemoved(action.payload.ids));
      }),
      retryWithToast(action$, logger),
      takeUntil(locationChanged$),
    )),
  );
};

//3.12.Editable order templates
const requestProductEpic: Epic<OrderTemplateAction> = (action$, _state$, { api, logger }) => {

  return action$.pipe(
    ofType(ORDER_TEMPLATE_PRODUCTS_REQUESTED),
    switchMap(
      action => api.graphApi<ProductsQueryResponse>(productsQuery, { options: { ids: action.payload.ids, loadAllProducts: action.payload.loadAllProducts } })
        .pipe(
          pluck('catalog', 'products', 'products'),
          mergeMap(products => {
            return of(receiveProducts(products), unsetLoadingIndicator());
          }),
          retryWithToast(action$, logger),
          startWith(setLoadingIndicator()),
        )),
  );
};

//3.12.Editable order templates
const saveOrderTemplateEpic: Epic<SaveOrderTemplateAction> = (action$, state$, dependencies) => {

  let templateForm = FormName.CreateOrderTemplate;
  return action$.pipe(
    ofType(ORDER_TEMPLATE_CREATED),
    pluck('payload'),
    switchMap(({ input }) => dependencies.api.graphApi<CreateOrderTemplateResponse>(saveOrderTemplateMutation, { input }).pipe(
      mergeMap(({ orderTemplates }) => {
        const { isEdit } = input;
        templateForm = isEdit ? FormName.EditOrderTemplate : FormName.CreateOrderTemplate;

        if (orderTemplates?.saveTemplate?.isError)
          return of(orderTemplateReceivedErrorMessage(orderTemplates?.saveTemplate), unsetLoadingIndicator(), unlockForm(templateForm));

        if(isEdit)
          toasts.success('', { textKey: 'OrderTemplate_Updated' });
        else
          toasts.success('', { textKey: 'OrderTemplate_Created' });

        return of(navigateTo(routesBuilder.forOrderTemplates()), unlockForm(templateForm));
      }),
      catchApiErrorWithToast(['INVALID_INPUT'], of(unlockForm(templateForm), unsetLoadingIndicator())),
      retryWithToast(action$, dependencies.logger, () => of(unlockForm(templateForm), unsetLoadingIndicator())),
      startWith(setLoadingIndicator()),
    )),
  );
};

//3.12.Editable order templates
const editOrderTemplateEpic: Epic<SaveOrderTemplateAction> = (action$, state$, dependencies) => {

    let templateForm = FormName.CreateOrderTemplate;
    return action$.pipe(
        ofType(ORDER_TEMPLATE_EDITED),
        pluck('payload'),
        switchMap(({ id, modified, pageIndex }) => dependencies.api.graphApi<EditOrderTemplateResponse>(editOrderTemplateMutation, { data: { id, modifiedLines: { modified }, pageIndex } }).pipe(
            mergeMap(({ orderTemplates }) => {
                templateForm = FormName.EditOrderTemplate;

                if (orderTemplates?.editTemplate?.isError)
                    return of(orderTemplateReceivedErrorMessage(orderTemplates?.editTemplate), unsetLoadingIndicator(), unlockForm(templateForm));

                toasts.success('', { textKey: 'OrderTemplate_Updated' });

                return of(orderTemplateReset(true), navigateTo(routesBuilder.forOrderTemplates()), unlockForm(templateForm));
            }),
            catchApiErrorWithToast(['INVALID_INPUT'], of(unlockForm(templateForm), unsetLoadingIndicator())),
            retryWithToast(action$, dependencies.logger, () => of(unlockForm(templateForm), unsetLoadingIndicator())),
            startWith(setLoadingIndicator()),
        )),
    );
};

const editOrderTemplateUpdateLinesEpic: Epic<EditOrderTemplateUpdateLinesAction> = (action$, state$, dependencies) => {

    let templateForm = FormName.CreateOrderTemplate;
    return action$.pipe(
        ofType(ORDER_TEMPLATE_EDIT_UPDATE_LINES),
        pluck('payload'),
        switchMap(({ id, modified, pageIndex }) => dependencies.api.graphApi<EditOrderTemplateResponse>(editOrderTemplateUpdateLinesMutation, { data: { id, modifiedLines: { modified }, pageIndex } }).pipe(
            mergeMap(({ orderTemplates }) => {
                templateForm = FormName.EditOrderTemplate;

                if (orderTemplates?.editTemplate?.isError)
                    return of(orderTemplateReceivedErrorMessage(orderTemplates?.editTemplate), unsetLoadingIndicator(), unlockForm(templateForm));

                toasts.success('', { textKey: 'OrderTemplate_Lines_Updated' });
                return of(editOrderTemplateLinesUpdated(Date.now()), unsetLoadingIndicator());
            }),
            catchApiErrorWithToast(['INVALID_INPUT'], of(unlockForm(templateForm), unsetLoadingIndicator())),
            retryWithToast(action$, dependencies.logger, () => of(unlockForm(templateForm), unsetLoadingIndicator())),
            startWith(setLoadingIndicator()),
        )),
    );
};

const searchOrderTemplateLinesEpic: Epic<EditOrderTemplateLinesSearchAction> = (action$, state$, dependencies) => {

    const settings = state$.value.settings;
    const pageSizeLocal = (settings as LoadedSettings).orderTemplatePageSize ?? defaultPageSize;
    const pageSize = isNaN(Number(pageSizeLocal)) ? defaultPageSize : Number(pageSizeLocal);

    return action$.pipe(
        ofType(ORDER_TEMPLATE_EDIT_LINES_SEARCH),
        pluck('payload'),
        switchMap(({ id, keyword, pageIndex }) => dependencies.api.graphApi<SearchOrderTemplateLinesResponse>(searchOrderTemplateLinesQuery, { id, keyword, index: pageIndex ,size: pageSize }).pipe(
            mergeMap(({ orderTemplate }) => {
                return of(editOrderTemplateLinesSearchReceived(keyword, orderTemplate?.lines), unsetLoadingIndicator());
            }),
            catchApiErrorWithToast(['INVALID_INPUT'], of(unsetLoadingIndicator())),
            retryWithToast(action$, dependencies.logger, () => of(unsetLoadingIndicator())),
            startWith(setLoadingIndicator()),
        )),
    );
};

const addNewLineToTemplateEpic: Epic<AddNewLineToTemplateAction> = (action$, state$, dependencies) => {

    return action$.pipe(
        ofType(ORDER_TEMPLATE_EDIT_ADD_NEW_LINE),
        pluck('payload'),
        switchMap(({ input: { id, name, isEdit, lines, keyword } }) => dependencies.api.graphApi<EditOrderTemplateAddNewLineResponse>(addNewLineToEditTemplateMutation, { input: { id, name, isEdit, lines } }).pipe(
            mergeMap(({ orderTemplates }) => {

                if (orderTemplates?.addLineToTemplate?.isError)
                    return of(orderTemplateReceivedErrorMessage(orderTemplates?.addLineToTemplate), unsetLoadingIndicator());

                toasts.success('', { textKey: 'OrderTemplate_Lines_Added' });

                const params = state$.value.routing.routeData?.params;
                const pageIndex = (params?.page as number) ?? 0;

                return of(editOrderTemplateLinesSearch(id, keyword, pageIndex !== 0 ? pageIndex - 1 : pageIndex), unsetLoadingIndicator());
            }),
            catchApiErrorWithToast(['INVALID_INPUT'], of(unsetLoadingIndicator())),
            retryWithToast(action$, dependencies.logger, () => of(unsetLoadingIndicator())),
            startWith(setLoadingIndicator()),
        )),
    );
};

//3.12.Editable order templates
const addLinesToBasketEpic: Epic<AddLinesToBasketTemplateAction> = (action$, state$, { api, logger }) => {

    let templateForm = FormName.CreateOrderTemplate;
    return action$.pipe(
        ofType(ORDER_TEMPLATE_BASKET_LINE_LIST_ADDING_REQUESTED),
        pluck('payload'),
        switchMap(({ input }) => api.graphApi<AddLinesToBasketResponse>(lineAddToBasketMutation, { input }).pipe(
            mergeMap(({ orderTemplates }) => {
                const { isEdit } = input;
                templateForm = isEdit ? FormName.EditOrderTemplate : FormName.CreateOrderTemplate;

                if (orderTemplates?.addLinesToBasket?.isError)
                    return of(orderTemplateReceivedErrorMessage(orderTemplates?.addLinesToBasket), unsetLoadingIndicator(), unlockForm(templateForm));

                toasts.success('', { textKey: 'OrderTemplate_SavedAndAddItemsToBasket' });

                return of(navigateTo(routesBuilder.forBasket()));
            }),
            catchApiErrorWithToast(['INVALID_INPUT'], of(unlockForm(templateForm), unsetLoadingIndicator())),
            retryWithToast(action$, logger, () => of(unlockForm(templateForm), unsetLoadingIndicator())),
            startWith(setLoadingIndicator()),
        )),
    );
};

const addLinesToBasketFromEditTemplateEpic: Epic<AddLinesToBasketFromEditTemplateAction> = (action$, state$, { api, logger }) => {

    let templateForm = FormName.CreateOrderTemplate;
    return action$.pipe(
        ofType(EDIT_ORDER_TEMPLATE_LINES_ADDING_TO_BASKET),
        pluck('payload'),
        switchMap(({ id, modified }) => api.graphApi<AddLinesToBasketResponse>(lineAddToBasketFromEditTemplateMutation, { data: { id, modifiedLines: { modified } } }).pipe(
            mergeMap(({ orderTemplates }) => {
                templateForm = FormName.EditOrderTemplate;

                if (orderTemplates?.addLinesToBasket?.isError)
                    return of(orderTemplateReceivedErrorMessage(orderTemplates?.addLinesToBasket), unsetLoadingIndicator(), unlockForm(templateForm));

                toasts.success('', { textKey: 'OrderTemplate_SavedAndAddItemsToBasket' });

                return of(navigateTo(routesBuilder.forBasket()));
            }),
            catchApiErrorWithToast(['INVALID_INPUT'], of(unlockForm(templateForm), unsetLoadingIndicator())),
            retryWithToast(action$, logger, () => of(unlockForm(templateForm), unsetLoadingIndicator())),
            startWith(setLoadingIndicator()),
        )),
    );
};

//3.12.Editable order templates
const deleteBasketLineEpic: Epic<DeleteBasketLine> = (action$, state$, { api, logger }) => {

    return action$.pipe(
        ofType(ORDER_TEMPLATE_DELETE_BASKET_LINE),
        pluck('payload'),
        switchMap(({ line, orderTemplateId, keyword }) => api.graphApi(removeProductFromOrderTemplateMutation, { input: { line, orderTemplateId } }).pipe(
            mergeMap(() => {

                toasts.success('', { textKey: 'OrderTemplate_BasketLineSuccessfullyDeleted' });

                const params = state$.value.routing.routeData?.params;
                const pageIndex = (params?.page as number) ?? 0;

                return of(editOrderTemplateLinesSearch(orderTemplateId, keyword, pageIndex !== 0 ? pageIndex - 1 : pageIndex), unsetLoadingIndicator());
            }),
            catchApiErrorWithToast(['INVALID_INPUT'], of(unsetLoadingIndicator())),
            retryWithToast(action$, logger, () => of(unsetLoadingIndicator())),
            startWith(setLoadingIndicator()),
        )),
    );
};

//3.12.Editable order templates
const clearAllBasketLinesEpic: Epic<ClearAllBasketLines> = (action$, state$, { api, logger }) => {

    return action$.pipe(
        ofType(ORDER_TEMPLATE_BASKET_CLEAR_ALL_LINES),
        pluck('payload'),
        switchMap(({ id }) => api.graphApi(deleteAllBasketLinesMutation, { id }).pipe(
            mergeMap(() => {

                toasts.success('', { textKey: 'OrderTemplate_SuccessfullyCleared' });
                return of(navigateTo(routesBuilder.forEditOrderTemplate(id)), unsetLoadingIndicator());
            }),
            catchApiErrorWithToast(['INVALID_INPUT'], of(unsetLoadingIndicator())),
            retryWithToast(action$, logger, () => of(unsetLoadingIndicator())),
            startWith(setLoadingIndicator()),
        )),
    );
};

const pastOrdersRequestEpic : Epic<PastOrderRequestAction> = (action$, _, { api }) => {
    return action$.pipe(
        ofType(PAST_ORDERS_REQUESTED),
        pluck('payload'),
        switchMap(({ productId }) => api.graphApi<PastOrdersResponse>(pastOrdersQuery, { productId }).pipe(
            mergeMap(({ pastOrders }) => {
                return of(receivePastOrders(pastOrders, productId), unsetLoadingIndicator());
            }),
            startWith(setLoadingIndicator()),
        )),
    );
};

export default combineEpics(
  loadOrderTemplatesEpic,
  loadOrderTemplateLinesEpic,
  addToBasketEpic,
  orderTemplatesRemoveEpic,
  requestProductEpic,
  saveOrderTemplateEpic,
  addLinesToBasketEpic,
  deleteBasketLineEpic,
  clearAllBasketLinesEpic,
  pastOrdersRequestEpic,
  editOrderTemplateEpic,
  editOrderTemplateUpdateLinesEpic,
  searchOrderTemplateLinesEpic,
  addNewLineToTemplateEpic,
  addLinesToBasketFromEditTemplateEpic,
);

function isTrackingEnabled(state: AppState) {
  return state.analytics && state.analytics.isTrackingEnabled;
}

export type ProductsQueryResponse = {
  catalog: {
    products: {
      products: Product[];
    };
  };
};

export type SaveOrderTemplateResponse = {
  orderTemplates: {
    save: SaveOrderTemplateResult;
  } | null;
};

export type CreateOrderTemplateResponse = {
  orderTemplates: {
    saveTemplate: SaveOrderTemplateResult;
  } | null;
};

export type EditOrderTemplateResponse = {
  orderTemplates: {
    editTemplate: SaveOrderTemplateResult;
  } | null;
};

export type EditOrderTemplateAddNewLineResponse = {
    orderTemplates: {
        addLineToTemplate: SaveOrderTemplateResult;
    } | null;
};

type AddLinesToBasketResponse = {
  orderTemplates: {
    addLinesToBasket: SaveOrderTemplateResult;
  } | null;
};

export type SearchOrderTemplateLinesResponse = {
    orderTemplate: {
        lines?: OrderTemplateLine[] | null;
    } | null;
};

type PastOrdersResponse = {
  pastOrders: Array<
          {
              orderDate: string;
              price: number;
              orderStatus: string;
              quantity: number;
              uom: string;
              totalPrice: number;
          }
      > ;
};