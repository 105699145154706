//3.7. Approve, or decline price change requests and view all price change requests
import loadable from '@loadable/component';

const PriceRequests = loadable(() => import(/*webpackChunkName:"order-tmpl"*/'./list/PriceRequests'));
const PriceRequest = loadable(() => import(/*webpackChunkName:"order-tmpl"*/'./details/PriceRequest'));

/* eslint-disable react/no-multi-comp */
export function priceRequestsRenderer(_page) {
  return <PriceRequests filter={_page.filter} priceRequests={_page.priceRequests} notify={_page.notify} />;
}

/* eslint-disable react/no-multi-comp */
export function priceRequestDetailsRenderer(_page) {
  return <PriceRequest priceRequest={_page.priceRequest} />;
}