export const enum ShippingAddressOption {
  Billing = 'BILLING',
  Existing = 'EXISTING',
  Custom = 'CUSTOM',
}

export const enum CheckoutProcessNames {
  Order = 'ORDER',
  Quote = 'QUOTE',
  EditOrder = 'EDIT_ORDER',
  EditQuote = 'EDIT_QUOTE',
  Invoice = 'INVOICE',
  Promotion = 'QuotePromotion',
}

export const enum Steps {
  Address = 'ADDRESS',
  Shipping = 'SHIPPING',
  Payment = 'PAYMENT',
  CustomerData = 'ADDITIONAL_CUSTOMER_DATA',
  ExtraPayment = 'EXTRA_PAYMENT',
  Overview = 'OVERVIEW',
  None = 'N/A',
}

export const enum TermsAndAgreementsVisibility {
  Invisible = 'INVISIBLE',
  VisibleNotSelected = 'VISIBLE_NOT_SELECTED',
  VisibleSelected = 'VISIBLE_SELECTED',
}

//3.5. Submit price change requests
export const enum PriceRequestFor {
    IndividualPrice = 'INDVIDUAL_PRICE',
    GroupPrice = 'GROUP_PRICE',
}

//3.7. Approve, or decline price change requests and view all price change requests
//export const enum PriceRequestStatus {
//    Pending = 'Pending',
//    Draft = 'Draft',
//    Withdrawn = 'Withdrawn',
//    Rejected = 'Rejected',
//    Approved = 'Approved',
//    PendingPinnaclePricingApproval = 'Pending Pinnacle Pricing approval',
//    RejectedPinnaclePricingApproval = 'Rejected requests by pinnacle team members',
//    ApprovedPinnaclePricingApproval = 'Approved requests by pinnacle team members',
//}

export const enum PriceRequestStatus {
    PendingManagerApproval = 'PendingManagerApproval',
    Draft = 'Draft',
    Withdrawn = 'Withdrawn',
    RejectedByManager = 'RejectedByManager',
    PendingPinnaclePricingApproval = 'PendingPinnaclePricingApproval',
    RejectedByPinnaclePricing = 'RejectedByPinnaclePricing',
    Approved = 'Approved',
    AllRejected = 'AllRejected',
}