import type {
  OrderTemplate,
  OrderTemplateLine,
  Product,
  SaveOrderTemplateInput,
  SaveOrderTemplateResult,
  BasketLineInput,
  PastOrder,
  ModifiedLine,
  AddLineToOrderTemplateInput,
} from './types';

export const ORDER_TEMPLATES_REQUESTED = 'ORDER_TEMPLATES_REQUESTED' as const;
export const loadOrderTemplates = () => ({ type: ORDER_TEMPLATES_REQUESTED });

export const ORDER_TEMPLATES_RECEIVED = 'ORDER_TEMPLATES_RECEIVED' as const;
export const orderTemplatesReceived = ({ orderTemplates }: { orderTemplates: OrderTemplate[] | null }) => ({
  type: ORDER_TEMPLATES_RECEIVED,
  payload: orderTemplates,
});
export type OrderTemplatesReceivedAction = ReturnType<typeof orderTemplatesReceived>;

export const ORDER_TEMPLATE_LINES_REQUESTED = 'ORDER_TEMPLATE_LINES_REQUESTED' as const;
export const loadOrderTemplateLines = (id: string) => ({
  type: ORDER_TEMPLATE_LINES_REQUESTED,
  payload: { id },
});

export const ORDER_TEMPLATE_LINES_RECEIVED = 'ORDER_TEMPLATE_LINES_RECEIVED' as const;
export const orderTemplateLinesReceived = (id: string, orderTemplateLines: OrderTemplateLine[] | null = null) => ({
  type: ORDER_TEMPLATE_LINES_RECEIVED,
  payload: { id, orderTemplateLines },
});
export type OrderTemplatesLinesReceivedAction = ReturnType<typeof orderTemplateLinesReceived>;

export const ORDER_TEMPLATES_ADDING_REQUESTED = 'ORDER_TEMPLATES_ADDING_REQUESTED' as const;
export const addToBasket = (ids: string[]) => ({
  type: ORDER_TEMPLATES_ADDING_REQUESTED,
  payload: { ids },
});

export const ORDER_TEMPLATES_ADDED = 'ORDER_TEMPLATES_ADDED' as const;
export const orderTemplatesAdded = (countAdded: number) => ({
  type: ORDER_TEMPLATES_ADDED,
  payload: countAdded,
});
export type OrderTemplatesAddedAction = ReturnType<typeof orderTemplatesAdded>;

export const ORDER_TEMPLATES_REMOVAL_REQUESTED = 'ORDER_TEMPLATES_REMOVAL_REQUESTED' as const;
export const removeTemplates = (ids: string[]) => ({
  type: ORDER_TEMPLATES_REMOVAL_REQUESTED,
  payload: { ids },
});

export const ORDER_TEMPLATES_REMOVED = 'ORDER_TEMPLATES_REMOVED' as const;
export const orderTemplatesRemoved = (ids: string[]) => ({
  type: ORDER_TEMPLATES_REMOVED,
  payload: ids,
});
export type OrderTemplatesRemovedAction = ReturnType<typeof orderTemplatesRemoved>;

//3.12.Editable order templates
export const ORDER_TEMPLATE_PRODUCTS_REQUESTED = 'ORDER_TEMPLATE_PRODUCTS_REQUESTED' as const;
export const requestProducts = (ids: string[], loadAllProducts: boolean) => ({
  type: ORDER_TEMPLATE_PRODUCTS_REQUESTED,
  payload: { ids, loadAllProducts },
});

//3.12.Editable order templates
export const ORDER_TEMPLATE_PRODUCTS_RECEIVED = 'ORDER_TEMPLATE_PRODUCTS_RECEIVED' as const;
export const receiveProducts = (products: Product[]) => ({
  type: ORDER_TEMPLATE_PRODUCTS_RECEIVED,
  payload: { products },
});

export type OrderTemplateReceiveProductsAction = ReturnType<typeof receiveProducts>;

//3.12.Editable order templates
export const ORDER_TEMPLATE_DELETE_BASKET_LINE = 'ORDER_TEMPLATE_DELETE_BASKET_LINE' as const;
export const deleteBasketLine = (line: BasketLineInput, orderTemplateId: string, keyword: string) => ({
    type: ORDER_TEMPLATE_DELETE_BASKET_LINE,
    payload: { line, orderTemplateId, keyword },
});
export type DeleteBasketLine = ReturnType<typeof deleteBasketLine>;

//3.12.Editable order templates
export const ORDER_TEMPLATE_BASKET_CLEAR_ALL_LINES = 'ORDER_TEMPLATE_BASKET_CLEAR_ALL_LINES' as const;
export const clearAllBasketLines = (id: string) => ({
    type: ORDER_TEMPLATE_BASKET_CLEAR_ALL_LINES,
    payload: { id },
});

export const PAST_ORDERS_REQUESTED = 'PAST_ORDERS_REQUESTED' as const;
export const requestPastOrders = (productId: string) => ({
    type: PAST_ORDERS_REQUESTED,
    payload: {
        productId,
    },
});
export type PastOrderRequestAction = ReturnType<typeof requestPastOrders>;

export const PAST_ORDERS_RECEIVED = 'PAST_ORDERS_RECEIVED' as const;
export const receivePastOrders = (pastOrders: PastOrder[], productId: string) => ({
    type: PAST_ORDERS_RECEIVED,
    payload: { pastOrders, productId },
});
export type PastOrderRecievedAction = ReturnType<typeof receivePastOrders>;

export type ClearAllBasketLines = ReturnType<typeof clearAllBasketLines>;

export const ORDER_TEMPLATE_CREATED = 'ORDER_TEMPLATE_CREATED' as const;
export const saveOrderTemplate = (input: SaveOrderTemplateInput) => ({
  type: ORDER_TEMPLATE_CREATED,
  payload: { input },
});
export type SaveOrderTemplateAction = ReturnType<typeof saveOrderTemplate>;

export const ORDER_TEMPLATE_EDITED = 'ORDER_TEMPLATE_EDITED' as const;
export const editOrderTemplate = (id: string, modified: ModifiedLine[], pageIndex = 0 ) => ({
    type: ORDER_TEMPLATE_EDITED,
    payload: { id, modified, pageIndex },
});
export type EditOrderTemplateAction = ReturnType<typeof editOrderTemplate>;

export const ORDER_TEMPLATE_EDIT_UPDATE_LINES = 'ORDER_TEMPLATE_EDIT_UPDATE_LINES' as const;
export const editOrderTemplateUpdateLines = (id: string, modified: ModifiedLine[], pageIndex = 0) => ({
    type: ORDER_TEMPLATE_EDIT_UPDATE_LINES,
    payload: { id, modified, pageIndex },
});
export type EditOrderTemplateUpdateLinesAction = ReturnType<typeof editOrderTemplateUpdateLines>;

export const ORDER_TEMPLATE_EDIT_LINES_UPDATED = 'ORDER_TEMPLATE_EDIT_LINES_UPDATED' as const;
export const editOrderTemplateLinesUpdated = (modifiedDate: number) => ({
    type: ORDER_TEMPLATE_EDIT_LINES_UPDATED,
    payload: modifiedDate,
});
export type EditOrderTemplateLinesUpdatedAction = ReturnType<typeof editOrderTemplateLinesUpdated>;

export const ORDER_TEMPLATE_EDIT_LINES_SEARCH = 'ORDER_TEMPLATE_EDIT_LINES_SEARCH' as const;
export const editOrderTemplateLinesSearch = (id: string, keyword: string, pageIndex = 0) => ({
    type: ORDER_TEMPLATE_EDIT_LINES_SEARCH,
    payload: { id, keyword, pageIndex },
});
export type EditOrderTemplateLinesSearchAction = ReturnType<typeof editOrderTemplateLinesSearch>;

export const ORDER_TEMPLATE_EDIT_SEARCHED_LINES_RECEIVED = 'ORDER_TEMPLATE_EDIT_SEARCHED_LINES_RECEIVED' as const;
export const editOrderTemplateLinesSearchReceived = (keyword: string | null = null, lines: OrderTemplateLine[] | null = null) => ({
    type: ORDER_TEMPLATE_EDIT_SEARCHED_LINES_RECEIVED,
    payload: { keyword, lines },
});
export type EditOrderTemplateLinesSearchReceivedAction = ReturnType<typeof editOrderTemplateLinesSearchReceived>;

export const ORDER_TEMPLATE_EDIT_ADD_NEW_LINE = 'ORDER_TEMPLATE_EDIT_ADD_NEW_LINE' as const;
export const addNewLineToTemplate = (input: AddLineToOrderTemplateInput) => ({
  type: ORDER_TEMPLATE_EDIT_ADD_NEW_LINE,
  payload: { input },
});
export type AddNewLineToTemplateAction = ReturnType<typeof addNewLineToTemplate>;

export const ORDER_TEMPLATE_CREATE_FAILED = 'ORDER_TEMPLATE_CREATE_FAILED' as const;
export const orderTemplateReceivedErrorMessage = (result: SaveOrderTemplateResult) => ({
  type: ORDER_TEMPLATE_CREATE_FAILED,
  payload: result,
});
export type OrderTemplateReceivedErrorMessageAction = ReturnType<typeof orderTemplateReceivedErrorMessage>;

export const ORDER_TEMPLATE_RESET = 'ORDER_TEMPLATE_RESET' as const;
export const orderTemplateReset = (resetUpdatedTemplate: boolean) => ({
    type: ORDER_TEMPLATE_RESET,
    payload: resetUpdatedTemplate
,
});
export type ordetTemplateResetAction = ReturnType<typeof orderTemplateReset>;

export const ORDER_TEMPLATE_BASKET_LINE_LIST_ADDING_REQUESTED = 'ORDER_TEMPLATE_BASKET_LINE_LIST_ADDING_REQUESTED' as const;
export const addLinesToBasket = (input: SaveOrderTemplateInput) => ({
  type: ORDER_TEMPLATE_BASKET_LINE_LIST_ADDING_REQUESTED,
  payload: { input },
});
export type AddLinesToBasketTemplateAction = ReturnType<typeof addLinesToBasket>;

export const EDIT_ORDER_TEMPLATE_LINES_ADDING_TO_BASKET = 'EDIT_ORDER_TEMPLATE_LINES_ADDING_TO_BASKET' as const;
export const addLinesToBasketFromEditTemplate = (id: string, modified: ModifiedLine[]) => ({
    type: EDIT_ORDER_TEMPLATE_LINES_ADDING_TO_BASKET,
    payload: { id, modified },
});
export type AddLinesToBasketFromEditTemplateAction = ReturnType<typeof addLinesToBasketFromEditTemplate>;

export type OrderTemplateAction = ReturnType<
  | typeof loadOrderTemplates
  | typeof orderTemplatesReceived
  | typeof loadOrderTemplateLines
  | typeof orderTemplateLinesReceived
  | typeof addToBasket
  | typeof orderTemplatesAdded
  | typeof removeTemplates
  | typeof orderTemplatesRemoved
  | typeof requestProducts
  | typeof receiveProducts
  | typeof saveOrderTemplate
  | typeof orderTemplateReceivedErrorMessage
  | typeof addLinesToBasket
  | typeof receivePastOrders
  | typeof orderTemplateReset
  | typeof editOrderTemplateLinesUpdated
  | typeof editOrderTemplateLinesSearchReceived
>;
