import type { Scope } from 'utils/app';
import type { Epic } from 'behavior/types';
import type { AppState } from 'behavior';
import { StateObservable } from 'redux-observable';
import { switchMap, map, mergeMap, takeUntil, startWith } from 'rxjs/operators';
import { LOCATION_CHANGED } from 'behavior/events';
import { setLoadingIndicator, unsetLoadingIndicator } from 'behavior/loadingIndicator';
import { of } from 'rxjs';
import { retryWithToast } from 'behavior/errorHandling';
import { parse, format } from 'url';
import { OfflineModeSupport } from 'behavior/app';
import { ofType } from 'redux-observable';
import { PageComponentNames } from './componentNames';
import { createUrl } from 'behavior/routing';
import { parseQuery } from 'utils/url';
import { RouteName } from 'routes';
import { Action, AnyAction } from 'redux';

export function createApiCallEpic<TActions extends AnyAction = Action<unknown>, R = any>(loadActionName: string, loadQuery: string, mapResponseToAction: (response: R) => Action) {
  const epic: Epic<TActions> = (action$, _, { api, logger }) => {
    const locationChanged$ = action$.pipe(ofType(LOCATION_CHANGED));

    return action$.pipe(
      ofType(loadActionName),
      switchMap(action => api.graphApi<R>(loadQuery, action.payload).pipe(
        mergeMap(response => of(
          mapResponseToAction(response),
          unsetLoadingIndicator(),
        )),
        retryWithToast(action$, logger),
        takeUntil(locationChanged$),
        startWith(setLoadingIndicator()),
      )),
    );
  };

  return epic;
}

export const addPageParamToUrl = (url: string, pageIndex: number) => {
  const urlObj = parse(url, true);
  const query = urlObj.query;
  delete query.page;

  if (pageIndex !== 1) // Don't show paging in URL for the first page.
    query.page = '' + pageIndex;

  urlObj.search = null; // Query object is used to create URL only in case when search field is absent.
  return format(urlObj);
};

//3.12.Editable order templates
export const addParamToTemplateEditUrl = (url: string, id: string, pageIndex: number) => {
    const urlObj = parse(url, true);
    const query = urlObj.query;
    delete query.page;
    query.id = id;
    if (pageIndex !== 1) // Don't show paging in URL for the first page.
        query.page = '' + pageIndex;

    urlObj.search = null; // Query object is used to create URL only in case when search field is absent.
    return format(urlObj);
};

export function initComponent<TPage, TComponent extends PageComponentNames>(component: TComponent) {
  type ResultPage = {
    page: TPage & { component: TComponent };
  };

  return map<TPage, ResultPage | null>(page => {

    if (!page)
      return null;

    const result = { page } as ResultPage;

    result.page.component = component;

    return result;
  });
}

export function offlineModeSupported(offlineModeSupport: OfflineModeSupport) {
  return offlineModeSupport !== OfflineModeSupport.Disabled;
}

export function getBackTo(state$: StateObservable<AppState>, ignoredRouteNames: RouteName[] = [], language: number | null = null) {
  const state = state$.value;
  const { routeData, location } = state.routing;
  if (!location || !routeData || (language && routeData.params && routeData.params.language !== language))
    return;

  if (!ignoredRouteNames.includes(routeData.routeName))
    return { url: createUrl(location), routeData };

  const backTo = state.page.backTo;
  if (backTo && !ignoredRouteNames.includes(backTo.routeData.routeName))
    return backTo;

  return;
}

export function getBackToFromUrl(scope: Scope): { url: string; routeData?: undefined } | undefined {
  if (scope !== 'CLIENT')
    return;

  const { search, hash } = window.location;
  if (!search)
    return;

  const query = parseQuery(search);
  const backurl = query.backurl;

  if (!backurl)
    return;

  return { url: backurl + hash };
}

export function createOfflinePage() {
  return {
    component: PageComponentNames.Offline,
    index: false,
  };
}
