import { ofType } from 'redux-observable';
import { merge, of, from, EMPTY, concat } from 'rxjs';
import { mergeMap, exhaustMap, first, tap } from 'rxjs/operators';
import { retryWithToast, catchApiErrorWithToast } from 'behavior/errorHandling';
import { trackCheckoutOption } from 'behavior/analytics';
import {
  checkoutInfoUpdated,
  CHECKOUT_SUBMIT_REQUESTED,
  saveAdditionalInfo,
  updateRequestedDateError,
} from './actions';
import {
  getSubmitMutation,
  setTrackedMutation,
} from './queries';
import { refreshCheckoutData } from './helpers';
import { routesBuilder } from 'routes';
import { basketReceived } from 'behavior/basket';
import { navigateTo } from 'behavior/events';
import { reloadLocation } from 'behavior/routing';
import { getStepNumber, getMethod } from './utils';
import { Steps } from './constants';
import { CheckoutPresets } from 'behavior/settings/constants';
import { requestAppStateUpdate, APP_STATE_UPDATED } from 'behavior/app';
import { postForm } from '../actions';
import { toasts } from 'behavior/toasts';
import { renderHTML } from 'utils/render';
import { DocumentType } from 'behavior/documents';
import { ANALYTICS_PURCHASE } from 'behavior/analytics/actions';
import { switchMap } from 'rxjs/operators';
import { ignoreElements } from 'rxjs/operators';

export default function createEpic(isIdleSubject) {
  return function (action$, state$, deps) {
    const isQuote = () => state$.value.page.info?.isQuote || false;
    const isPromotion = () => !!state$.value.page.info?.quote;
    const withPriceRequest = () => state$.value.page.info?.withPriceRequest || false; //3.6. Submitting the orders with a price change request
    const { api, logger } = deps;

    const checkoutSubmit$ = action$.pipe(
      ofType(CHECKOUT_SUBMIT_REQUESTED),
      exhaustMap(action => {
        const { additionalInfo } = action.payload;

        const state = state$.value;
        if (state.app.offlineMode && state.page.info.paymentMethods) {
          const actions = [requestAppStateUpdate()];
          if (additionalInfo)
            actions.push(saveAdditionalInfo(additionalInfo));

          return merge(from(actions), action$.pipe(
            ofType(APP_STATE_UPDATED),
            first(),
            mergeMap(action => {
              if (action.payload.offlineMode)
                return submit();

              return of(checkoutInfoUpdated({
                incompleteSteps: [
                  { type: Steps.Payment, titleKey: (isQuote() ? 'Quote' : '') + 'CheckoutStep_PaymentMethods' },
                ],
              }));
            }),
          ));
        }

        return submit(additionalInfo);
      }),
    );

    const purchaseTracked$ = action$.pipe(
      ofType(ANALYTICS_PURCHASE),
      switchMap(({ payload }) => api.graphApi(setTrackedMutation, { transactionId: payload.transaction.id })),
      ignoreElements(),
    );

    return merge(checkoutSubmit$, purchaseTracked$);

    function submit(additionalInfo) {
      isIdleSubject.next(false);

      //3.6. Submitting the orders with a price change request
      const params = { asQuote: isQuote(), withPriceRequest: withPriceRequest() };

      if (additionalInfo)
        params.input = additionalInfo;

      return api.graphApi(getSubmitMutation(additionalInfo, isPromotion()), params, { retries: 0 }).pipe(
        mergeMap(({ checkout: { submit } }) => {

          const state = state$.value;
          const isOneStep = state.settings.checkout.pagePreset === CheckoutPresets.OneStep;
          if (submit.incompleteSteps && submit.incompleteSteps.length)
            return updateIncompleteCheckoutInfo(submit.info, submit.incompleteSteps, isOneStep);

          const actions = [];
          if (isOneStep)
            addTrackCheckoutAction(state, actions);

          if (submit.nextAction) {
            return concat(actions, executeNextAction(submit.nextAction));
          }

          //3.5. Validation of the delivery window on checkout
          if (submit.invalidRequestedDeliveryDate) {
            isIdleSubject.next(true);
            return of(updateRequestedDateError({ invalidRequestedDeliveryDate: submit.invalidRequestedDeliveryDate }));
          }

          const route = getRouteToNavigate(submit);
          actions.push(navigateTo(route));

          if (submit.invalidBasket)
            return actions;

          addClearBasketAction(actions);

          return actions;
        }),
        catchApiErrorWithToast(undefined, of(reloadLocation()).pipe(
          tap(_ => isIdleSubject.next(true)),
        )),
        retryWithToast(action$, logger, () => {
          isIdleSubject.next(true);
          return EMPTY;
        }),
      );
    }

    function updateIncompleteCheckoutInfo(checkoutInfo = {}, incompleteSteps, isOneStep) {
      checkoutInfo.incompleteSteps = incompleteSteps;
      isIdleSubject.next(true);

      if (!isOneStep)
        return of(checkoutInfoUpdated(checkoutInfo));

      return concat(
        of(checkoutInfoUpdated(checkoutInfo)),
        refreshCheckoutData(state$, deps),
      );
    }

    function executeNextAction(nextAction) {
      if ('values' in nextAction)
        return of(postForm(nextAction));

      if ('message' in nextAction) {
        if (nextAction.message)
          toasts.info(
            nextAction.isHtmlMessage
              ? renderHTML(nextAction.message)
              : nextAction.message,
            { autoDismiss: false },
          );

        window.location.href = nextAction.url;
        return EMPTY;
      }

      logger.error('nextAction field is not handled.', nextAction);
      throw new Error('Unexpected nextAction.');
    }

    function getRouteToNavigate(submit) {
      if (submit.invalidBasket) {
        const checkoutInfo = state$.value.page.info.quote;
        return checkoutInfo ? routesBuilder.forDocument(checkoutInfo.quote.id, DocumentType.Quote) : routesBuilder.forBasket();
      }

      //3.6. Submitting the orders with a price change request
      if (submit.navigateToBasketPage) {
        return routesBuilder.forBasket();
      }
      const transaction = submit.transaction;

      if (transaction.cancelled)
        return routesBuilder.forOrderCancelled(transaction.id);

      if (transaction.failed)
        return routesBuilder.forOrderFailed(transaction.id);

      if (transaction.isPaymentError)
        return routesBuilder.forPaymentError(transaction.id);

      return routesBuilder.forOrderSubmit(transaction.id);
    }
  };
}

function addTrackCheckoutAction(state, actions) {
  const shippingOption = state.page.info.shippingAddress.shippingOption;
  shippingOption && actions.push(trackCheckoutOption({
    step: getStepNumber(Steps.Address),
    option: shippingOption,
  }));

  const { shippingMethods, shippingMethodId } = state.page.info;
  const shippingMethodOption = getMethod(shippingMethods, shippingMethodId);
  shippingMethodOption && actions.push(trackCheckoutOption({
    step: getStepNumber(Steps.Shipping),
    option: shippingMethodOption,
  }));

  const { paymentMethods, paymentMethodId } = state.page.info;
  const paymentMethodOption = getMethod(paymentMethods, paymentMethodId);
  paymentMethodOption && actions.push(trackCheckoutOption({
    step: getStepNumber(Steps.Payment),
    option: paymentMethodOption,
  }));
}

function addClearBasketAction(actions) {
  actions.push(basketReceived({
    id: '',
    productLines: {},
    totalCount: 0,
    cleared: true,
    modifiedDate: Date.now(),
  }));
}
