//Ticket 190109: [Changes to reminder emails] - Option to opt out from emails for customers and sales agents
export const RECEIVE_REMINDER_NOTIFICATIONS_CHECK = 'RECEIVE_REMINDER_NOTIFICATIONS_CHECK' as const;
export const reminderNotificationsSubscribe = (input: { isNotificationsSubscribed: boolean }) => ({
    type: RECEIVE_REMINDER_NOTIFICATIONS_CHECK,
    payload: { input },
});

export type MarketingPreferencesSelectAction = ReturnType<typeof reminderNotificationsSubscribe>;

export const RECEIVE_REMINDER_NOTIFICATIONS_CHECKED = 'RECEIVE_REMINDER_NOTIFICATIONS_CHECKED' as const;
export const reminderNotificationsSubscribed = (isNotificationSubscribed: boolean) => ({
    type: RECEIVE_REMINDER_NOTIFICATIONS_CHECKED,
    payload: { isNotificationSubscribed },
});

export type MarketingPreferencesSelectedAction = ReturnType<typeof reminderNotificationsSubscribed>;

export type MarketingPreferencesAction = ReturnType<
    | typeof reminderNotificationsSubscribe
    | typeof reminderNotificationsSubscribed
>;