export const enum Presets {
  B2C = 'DetailsB2C',
  B2B = 'DetailsB2B',
}

//3.5. Submit price change requests
export const enum SelectedBasketType {
    PriceRequest = 'PRICEREEQUEST',
    NormalBasket = 'NORMALBASKET',
}
