//Ticket 190109: [Changes to reminder emails] - Option to opt out from emails for customers and sales agents
import type { Handler } from '../types';
import type { MarketingPreference } from './types';
import { marketingPreferencesPageQuery } from './queries';
import { map } from 'rxjs/operators';
import { PageComponentNames } from '../componentNames';
import { initSystemPageContent, SystemPage, SystemPageData } from '../system';
import { loadSystemPageQuery } from '../system/queries';
import { RouteName } from 'routes';
import { getBackTo } from '../helpers';

const handler: Handler<MarketingPreferencesRouteData, MarketingPreferencesPage> = ({ params }, _state$, { api }) => {
    if (params?.previewToken) {
        return api.graphApi<SystemPageResponse>(loadSystemPageQuery('marketingPreferences')).pipe(
            map(({ pages: { marketingPreferences: page } }) => {
                if (!page)
                    return null;
                return {
                    page: { ...page, component: PageComponentNames.MarketingPreferences as const },
                };
            }),
            initSystemPageContent(),
        );
    }
    return api.graphApi<MarketingPreferencesPageResponse>(marketingPreferencesPageQuery).pipe(
        map(({ pages: { marketingPreferences: page }, viewer }) => {
            if (!page)
                return null;

            return {
                page: {
                    ...page,
                    component: PageComponentNames.MarketingPreferences as const,
                    marketingPreferences: viewer,
                    backTo: getBackTo(_state$, [RouteName.MarketingPreferences], params?.language),
                },
            };
        }),
        initSystemPageContent(),
    );
};

export default handler;

type MarketingPreferencesPage = SystemPage & {
    component: PageComponentNames.MarketingPreferences;
};

type MarketingPreferencesRouteData = {
    routeName: RouteName.MarketingPreferences;
    params?: {
        previewToken?: string;
        language: number;
    };
};

type SystemPageResponse = {
    pages: {
        marketingPreferences: SystemPageData;
    };
    viewer: MarketingPreference;
};

type MarketingPreferencesPageResponse = SystemPageResponse & { marketingPreferences: MarketingPreference };
