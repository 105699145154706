import { loadSubAccountsPageQuery, getLoadSubAccountsPreviewPageQuery } from './queries';
import { initPageContent, SystemPage, SystemPageData } from '../system';
import { map } from 'rxjs/operators';
import { PageComponentNames } from '../componentNames';
import { generateKey } from 'utils/helpers';
import type { Handler } from '../types';
import { SubAccountsOptions, SubAccountType } from './types';
import { RouteName } from 'routes';

type SubAccountsRouteData = {
  routeName: RouteName.SubAccounts;
  params?: {
    previewToken?: string;
  };
};

type SubAccountsPage = SystemPage & {
  component: PageComponentNames.SubAccounts;
  subAccounts: {
    list: SubAccountType[];
  };
  options: SubAccountsOptions;
  authorizers: {
    id: string;
    name: string;
  }[];
};

type SubAccountsSystemPageResponse = {
  pages: {
    subAccounts: SystemPageData;
  };
};

type SubAccountsPagePreviewResponse = SubAccountsSystemPageResponse & {
  profile: {
    subAccounts: {
      options: SubAccountsOptions;
    };
  };
};

type SubAccountsPageResponse = SubAccountsSystemPageResponse & {
  profile: {
    subAccounts: {
      options: SubAccountsOptions;
      search: {
        list: SubAccountType[];
      };
    };
  };
};

const handler: Handler<SubAccountsRouteData, SubAccountsPage> = ({ params }, _state$, { api }) => {
  if (params?.previewToken) {
    return api.graphApi<SubAccountsPagePreviewResponse>(getLoadSubAccountsPreviewPageQuery('subAccounts')).pipe(
      map(({ pages, profile: { subAccounts } }) => {
        const page = pages.subAccounts;
        if (!page)
          return null;

        const result = {
          page: initPageContent(page) as SubAccountsPage,
        };

        result.page.component = PageComponentNames.SubAccounts;
        result.page.subAccounts = {
          list: Array.from(Array(5)).map(() => ({
            id: generateKey(),
            name: '',
            email: '',
            active: true,
            canAuthorizeOrders: false,
            canOrder: true,
            canSeePrices: true,
            canSeeStock: true,
            limits: [],
            dependentAccounts: [],
            otherAccounts: [], //3.3. Centralized purchasing setup
          })),
        };
        result.page.options = subAccounts.options;

        return result;
      }),
    );
  }

  return api.graphApi<SubAccountsPageResponse>(loadSubAccountsPageQuery).pipe(
    map(({ pages, profile: { subAccounts } }) => {
      const page = pages.subAccounts;
      if (!page || !subAccounts)
        return null;

      const result = {
        page: initPageContent(page) as SubAccountsPage,
      };

      result.page.component = PageComponentNames.SubAccounts;
      result.page.subAccounts = subAccounts.search;
      result.page.options = subAccounts.options;
      return result;
    }),
  );
};

export default handler;
