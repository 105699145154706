//Ticket 190109: [Changes to reminder emails] - Option to opt out from emails for customers and sales agents
import type { Epic } from 'behavior/types';
import { MarketingPreferencesAction, RECEIVE_REMINDER_NOTIFICATIONS_CHECK, reminderNotificationsSubscribed } from './actions';
import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { mergeMap, switchMap, startWith } from 'rxjs/operators';
import { setLoadingIndicator, unsetLoadingIndicator } from 'behavior/loadingIndicator';
import { saveMarketingPreferencesMutation } from './queries';
import { catchApiErrorWithToast, retryWithToast } from 'behavior/errorHandling';
import { toasts } from '../../toasts';

const epic: Epic<MarketingPreferencesAction> = (action$, _state$, { api, logger }) => {

    return action$.pipe(
        ofType(RECEIVE_REMINDER_NOTIFICATIONS_CHECK),
        switchMap(({ payload: { input } }) => api.graphApi(saveMarketingPreferencesMutation, { input }).pipe(
            mergeMap(() => {
                toasts.success('', { textKey: 'Order_Reminder_Notifications_Subscription' });
                return of(unsetLoadingIndicator(), reminderNotificationsSubscribed(input.isNotificationsSubscribed));
            }),
            catchApiErrorWithToast(['INVALID_INPUT'], of(unsetLoadingIndicator())),
            retryWithToast(action$, logger, () => of(unsetLoadingIndicator())),
            startWith(setLoadingIndicator()),
            ),
        ),
    );
};

export default epic;
