import type { Epic } from 'behavior/types';
import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { switchMap, pluck, map, mergeMap, startWith } from 'rxjs/operators';
import { catchApiErrorWithToast, retryWithToast } from 'behavior/errorHandling';
import { unlockForm, FormName } from 'behavior/pages';
import { setLoadingIndicator, unsetLoadingIndicator } from 'behavior/loadingIndicator';
import { toasts } from 'behavior/toasts';
import { requestAbility } from 'behavior/user/epic';
import { AbilityTo, AbilityState } from 'behavior/user/constants';
import { DocumentType } from 'behavior/documents';
import { routesBuilder } from 'routes';
import { navigateTo } from 'behavior/events';
import { CreateDocBasedReturnOrderAction, DOC_BASED_RETURN_ORDER_SUBMITTED, setReturnOrderFailed } from './actions';
import { createReturnOrderMutation } from './queries';

const epic: Epic<CreateDocBasedReturnOrderAction> = (action$, state$, dependencies) => action$.pipe(
  ofType(DOC_BASED_RETURN_ORDER_SUBMITTED),
  pluck('payload'),
  switchMap(({ input, files }) =>
    dependencies.api.graphApi<CreateReturnOrderResponse>(createReturnOrderMutation, { input }, { files, retries: 0 }).pipe(
      pluck('documents', 'returnOrders', 'createDocBased'),
      mergeMap(returnOrder => {
        const { orderId, returnOrderRequest } = returnOrder;

        if (!orderId)
          return of(setReturnOrderFailed(), unsetLoadingIndicator());

        //TICKET 192407
        //toasts.success('', { textKey: 'ReturnRequest_OrderCreated' });

        return requestAbility(AbilityTo.ViewReturnOrders, state$, dependencies).pipe(
          map(abilityState => navigateTo(abilityState === AbilityState.Available
            ? routesBuilder.forDocument(orderId, returnOrderRequest ? DocumentType.Invoice : DocumentType.ReturnOrder)
            : routesBuilder.forInvoices())),
        );
      }),
      catchApiErrorWithToast(['INVALID_INPUT'], of(unlockForm(FormName.CreateDocBasedReturnOrder), unsetLoadingIndicator())),
      retryWithToast(action$, dependencies.logger, () => of(unlockForm(FormName.CreateDocBasedReturnOrder), unsetLoadingIndicator())),
      startWith(setLoadingIndicator()),
    )),
);

export default epic;

type CreateReturnOrderResponse = {
  documents: {
    returnOrders: {
      createDocBased: {
        orderId: string | null;
        returnOrderRequest: boolean;
      };
    };
  };
};

