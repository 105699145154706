import PropTypes from 'prop-types';
import { Container } from 'components/primitives/grid';
import BasketTitle from './BasketTitle';
import BasketBreadcrumb from './Breadcrumb';
import BasketPageContent from './BasketPageContent';
import { useSelector, shallowEqual } from 'react-redux';

const BasketPage = ({ preset, header, footer }) => {

  //3.5. Submit price change requests
  const { isPriceRequestPage } = useSelector(({ basket }) => ({
    isPriceRequestPage: basket.model?.isPriceRequestPage,
  }), shallowEqual);

  return (
    <>
      <Container>
        <BasketBreadcrumb isPriceRequestPage={isPriceRequestPage} />
        <BasketTitle isPriceRequestPage={isPriceRequestPage} />
      </Container>
      <BasketPageContent preset={preset} header={header} footer={footer} />
    </>
  );
};

BasketPage.propTypes = {
  preset: PropTypes.string,
  header: PropTypes.array,
  footer: PropTypes.array,
};

export default BasketPage;
