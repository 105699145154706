import type { Handler } from '../types';
import type { Action } from 'redux';
import type { OrderTemplate } from './types';
import { defaultPageSize, editOrderTemplatesPageQuery } from './queries';
import type { LoadedSettings } from 'behavior/settings';
import { map } from 'rxjs/operators';
import { of } from 'rxjs';
import { PageComponentNames } from '../componentNames';
import { initSystemPageContent, SystemPage, SystemPageData } from '../system';
import { orderTemplatesReceived } from './actions';
import { loadSystemPageQuery } from '../system/queries';
import { generateKey } from 'utils/helpers';
import { RouteName } from 'routes';

const editOrderTemplateHandler: Handler<EditOrderTemplateRouteData, EditOrderTemplatePage | NotFoundPage> = ({ params, options }, _state$, { api }) => {

 const id = params?.id;
 const pageIndex = getPageIndex(params);

 // #195218: [Changes to Order Templates] - Product search
 let keyword = '';
 if (params?.keyword)
    keyword = params?.keyword;

  if (params?.previewToken) {
    return api.graphApi<SystemPageResponse>(loadSystemPageQuery('editOrderTemplate')).pipe(
      map(({ pages: { editOrderTemplate: page } }) => {
        if (!page)
          return null;

        const orderTemplates = Array.from(Array(5)).map(() => ({ id: generateKey(), name: null, description: '', lines: [], createdDate: '', modifiedDate: '' }));

        return {
          page: { ...page, component: PageComponentNames.EditOrderTemplate as const },
          action$: of(orderTemplatesReceived({ orderTemplates })),
        };
      }),
      initSystemPageContent(),
    );
  }

    if (options) {
        const page = _state$.value.page as EditOrderTemplatePage;
        if (options.action)
            return of({ action$: of(options.action), page });

    }

    const settings = _state$.value.settings;
    const pageSizeLocal = (settings as LoadedSettings).orderTemplatePageSize ?? defaultPageSize;
    const pageSize = isNaN(Number(pageSizeLocal)) ? defaultPageSize : Number(pageSizeLocal);

    // #195218: [Changes to Order Templates] - Product search
    return api.graphApi<EditOrderTemplatePageResponse>(editOrderTemplatesPageQuery, { id, keyword, index: pageIndex, size: pageSize }).pipe(
    map(({ pages: { editOrderTemplate: page }, orderTemplate }) => {
      if (!page || !orderTemplate)
        return null;
      
      return {
        page: {
          ...page,
          orderTemplate,
          page: { index:pageIndex, size:pageSize },
          modifiedDate: Date.now(),
          component: PageComponentNames.EditOrderTemplate as const,
          inputKeyword: keyword, // #195218: [Changes to Order Templates] - Product search
          resetUpdatedTemplate: false,
        },
      };
    }),
    initSystemPageContent(),
  );
};

export default editOrderTemplateHandler;

type EditOrderTemplatePage = SystemPage & {
  component: PageComponentNames.EditOrderTemplate;
};

type EditOrderTemplateRouteData = {
  routeName: RouteName.EditOrderTemplate;
  params?: { previewToken?: string; id: string; keyword: string; page?: number}; // #195218: [Changes to Order Templates] - Product search
  options?: {
    action?: Action;
  };
};

type SystemPageResponse = {
  pages: {
    editOrderTemplate: SystemPageData;
  };
  orderTemplate: OrderTemplate;
};

type NotFoundPage = {
    component: PageComponentNames.NotFound;
};

type EditOrderTemplatePageResponse = SystemPageResponse;

function getPageIndex(params: EditOrderTemplateRouteData['params']) {
    let pageIndex = 0;
    if (params?.page != null)
        pageIndex = params.page - 1; // Numbering in URL starts from 1.

    return pageIndex;
}