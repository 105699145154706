import PropTypes from 'prop-types';
import { ShopAccountType, ShopAccountRoleTypes } from 'behavior/user';
import AnonymousMenuItems from './AnonymousMenuItems';
import CustomerMenuItems from './CustomerMenuItems';
import ImpersonatedCustomerMenuItems from './ImpersonatedCustomerMenuItems';
import SalesAgentMenuItems from './SalesAgentMenuItems';

const AccountMenuItems = ({ isAuthenticated, isImpersonating, shopAccountType, logoutDelay }) => {
  if (!isAuthenticated)
    return <AnonymousMenuItems />;

  //3.3. Centralized purchasing setup
  if (shopAccountType === ShopAccountType.SalesAgent || shopAccountType === ShopAccountType.Customer) {
    if (isImpersonating)
      return <ImpersonatedCustomerMenuItems logoutDelay={logoutDelay} />;

    if (shopAccountType === ShopAccountType.SalesAgent)
      return <SalesAgentMenuItems logoutDelay={logoutDelay} />;
    else
      return <CustomerMenuItems logoutDelay={logoutDelay} />;
  }

  return <CustomerMenuItems logoutDelay={logoutDelay} />;
};

AccountMenuItems.propTypes = {
  isAuthenticated: PropTypes.bool,
  isImpersonating: PropTypes.bool,
  shopAccountType: PropTypes.string,
  logoutDelay: PropTypes.number,
};

export default AccountMenuItems;
