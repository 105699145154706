import type { PageTemplatesState } from './types';
import type { PageTemplateAction } from './actions';
import type { UserAuthenticatedAction } from 'behavior/user';
import { USER_AUTHENTICATED } from 'behavior/user';
import { PRODUCT_PAGE_TEMPLATE_LOADED } from './actions';
import { parseContent } from 'behavior/content';

const initialState = { product: null };

export default (state: PageTemplatesState = initialState, action: PageTemplateAction | UserAuthenticatedAction): PageTemplatesState => {
  switch (action.type) {
    case PRODUCT_PAGE_TEMPLATE_LOADED:
      return {
        ...state,
        product: {
          desktop: parseContent(action.payload.page.desktop),
          mobile: parseContent(action.payload.page.mobile),
          languageId: action.payload.languageId,
          preset: action.payload.page.preset,
        },
      };
    case USER_AUTHENTICATED:
      if (!state.product)
        return state;

      return {
        ...state,
        product: {
          ...state.product,
          expired: true,
        },
      };
    default:
      return state;
  }
};
