//3.7. Approve, or decline price change requests and view all price change requests

export const WITHDRAW_PRICE_REQUEST = 'WITHDRAW_PRICE_REQUEST';
export const withdrawPriceRequest = requestNo => ({
  type: WITHDRAW_PRICE_REQUEST,
  payload: { requestNo },
});

export const PRICE_REQUEST_REMOVE_LINE = 'PRICE_REQUEST_REMOVE_LINE';
export const removePriceRequestLine = (requestNo, productId, unitOfMeasureId) => ({
  type: PRICE_REQUEST_REMOVE_LINE,
  payload: { requestNo, productId, unitOfMeasureId },
});

export const REJECT_PRICE_REQUEST = 'REJECT_PRICE_REQUEST';
export const rejectPriceRequest = (requestNo, isManager, addedComment) => ({
  type: REJECT_PRICE_REQUEST,
  payload: { requestNo, isManager, addedComment },
});

export const APPROVE_PRICE_REQUEST = 'APPROVE_PRICE_REQUEST';
export const approvePriceRequest = (requestNo, addedComment, isManager, modified) => ({
  type: APPROVE_PRICE_REQUEST,
  payload: { requestNo, addedComment, isManager, modified },
});

export const PRICE_REQUEST_LINES_UPDATE = 'PRICE_REQUEST_LINES_UPDATE';
export const priceRequestLinesUpdate = (requestNo, modified) => ({
  type: PRICE_REQUEST_LINES_UPDATE,
  payload: { requestNo, modified },
});

//3.9. Save price change requests
export const DELETE_PRICE_REQUEST = 'DELETE_PRICE_REQUEST';
export const deletePriceRequest = requestNo => ({
  type: DELETE_PRICE_REQUEST,
  payload: { requestNo },
});

//3.9. Save price change requests
export const EDIT_PRICE_REQUEST = 'EDIT_PRICE_REQUEST';
export const editPriceRequest = requestNo => ({
  type: EDIT_PRICE_REQUEST,
  payload: { requestNo },
});