//Ticket 190109: [Changes to reminder emails] - Option to opt out from emails for customers and sales agents
import type { RoutingAction } from 'behavior/routing/actions';
import {
    MarketingPreferencesAction,
    RECEIVE_REMINDER_NOTIFICATIONS_CHECKED,
    MarketingPreferencesSelectedAction,
} from './actions';
import { createReducer } from 'utils/redux';
import { PageComponentNames } from '../componentNames';

type State = {
    component: PageComponentNames.MarketingPreferences;
    isNotificationSubscribed?: boolean;
};

export default createReducer<State, MarketingPreferencesAction | RoutingAction>(null as unknown as State, {
    [RECEIVE_REMINDER_NOTIFICATIONS_CHECKED]: onReminderNotificationsSubscribed,
});

function onReminderNotificationsSubscribed(state: State, action: MarketingPreferencesSelectedAction) {
    return {
        ...state,
        isNotificationSubscribed: action.payload.isNotificationSubscribed,
    };
}

