import type { Handler } from '../types';
import type { OrderTemplate } from './types';
import { createOrderTemplatesPageQuery } from './queries';
import { map } from 'rxjs/operators';
import { of } from 'rxjs';
import { PageComponentNames } from '../componentNames';
import { initSystemPageContent, SystemPage, SystemPageData } from '../system';
import { orderTemplatesReceived } from './actions';
import { loadSystemPageQuery } from '../system/queries';
import { generateKey } from 'utils/helpers';
import { RouteName } from 'routes';

const createOrderTemplateHandler: Handler<CreateOrderTemplateRouteData, CreateOrderTemplatePage> = ({ params }, _state$, { api }) => {

  if (params?.previewToken) {
    return api.graphApi<SystemPageResponse>(loadSystemPageQuery('createOrderTemplate')).pipe(
      map(({ pages: { createOrderTemplate: page } }) => {
        if (!page)
          return null;

        const orderTemplates = Array.from(Array(5)).map(() => ({ id: generateKey(), name: null, description: '', lines: [], createdDate: '', modifiedDate: '' }));

        return {
          page: { ...page, component: PageComponentNames.CreateOrderTemplate as const },
          action$: of(orderTemplatesReceived({ orderTemplates })),
        };
      }),
      initSystemPageContent(),
    );
  }

  return api.graphApi<CreateOrderTemplatePageResponse>(createOrderTemplatesPageQuery).pipe(
    map(({ pages: { createOrderTemplate: page } }) => {
      if (!page)
        return null;
      return {
        page: { ...page, component: PageComponentNames.CreateOrderTemplate as const },
      };
    }),
    initSystemPageContent(),
  );
};

export default createOrderTemplateHandler;

type CreateOrderTemplatePage = SystemPage & {
  component: PageComponentNames.CreateOrderTemplate;
};

type CreateOrderTemplateRouteData = {
  routeName: RouteName.CreateOrderTemplate;
  params?: { previewToken?: string };
};

type SystemPageResponse = {
  pages: {
    createOrderTemplate: SystemPageData;
  };
};

type CreateOrderTemplatePageResponse = SystemPageResponse;
