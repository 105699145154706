import type { CustomerCollection } from './types';
import { PageComponentNames } from '../componentNames';
import { CUSTOMERS_RECEIVED, RepresentationAction } from './actions';

type State = {
  component: PageComponentNames.Represent;
  customers: CustomerCollection;
};

export default function (state: State, action: RepresentationAction) {
  if (action.type === CUSTOMERS_RECEIVED) {
    const newCustomers = action.payload.customers;
    newCustomers.keywords = action.payload.keywords;
    newCustomers.isMatchedByState = action.payload.isMatchedByState;

   if (action.payload.keywords === state.customers.keywords && (state.customers.isMatchedByState !== undefined && action.payload.isMatchedByState === state.customers.isMatchedByState)) {
      newCustomers.list = state.customers.list.concat(newCustomers.list);
    }

    return {
      ...state,
      customers: newCustomers,
    };
  }
  return state;
}
