import type { RoutingAction } from 'behavior/routing/actions';
import type { OrderTemplate, PastOrder, Product } from './types';
import {
  OrderTemplatesAddedAction,
  OrderTemplatesRemovedAction,
  OrderTemplatesReceivedAction,
  OrderTemplatesLinesReceivedAction,
  OrderTemplateAction,
  OrderTemplateReceiveProductsAction,
  ordetTemplateResetAction,
  ORDER_TEMPLATES_ADDED,
  ORDER_TEMPLATES_REMOVED,
  ORDER_TEMPLATES_RECEIVED,
  ORDER_TEMPLATE_LINES_RECEIVED,
  ORDER_TEMPLATE_PRODUCTS_RECEIVED,
  ORDER_TEMPLATE_CREATE_FAILED,
  ORDER_TEMPLATE_RESET,
  OrderTemplateReceivedErrorMessageAction,
  PAST_ORDERS_RECEIVED,
  PastOrderRecievedAction,
  ORDER_TEMPLATE_EDIT_LINES_UPDATED,
  EditOrderTemplateLinesUpdatedAction,
  ORDER_TEMPLATE_EDIT_SEARCHED_LINES_RECEIVED,
  EditOrderTemplateLinesSearchReceivedAction,
} from 'behavior/pages/orderTemplates/actions';
import { createReducer } from 'utils/redux';
import { UpdateType } from './constants';
import { PageComponentNames } from '../componentNames';
import { arrayToObject } from 'utils/helpers';

type StatusAdded = {
  type: UpdateType.Added;
  countAdded: number;
};

type StatusRemoved = {
  type: UpdateType.Removed;
};

type State = {
  component: PageComponentNames.OrderTemplates;
  status?: StatusAdded | StatusRemoved;
  items?: OrderTemplate[] | null;
  products: Record<string, Product> | null;
  isFailed?: false;
  pastOrders?: Record<string, PastOrder[]>;
  orderTemplate?: OrderTemplate | null;
  linesSearched: boolean | false;
};

export default createReducer<State, OrderTemplateAction | RoutingAction>(null as unknown as State, {
  [ORDER_TEMPLATES_ADDED]: onOrderTemplatesAdded,
  [ORDER_TEMPLATES_REMOVED]: onOrderTemplatesRemoved,
  [ORDER_TEMPLATES_RECEIVED]: onOrderTemplatesReceived,
  [ORDER_TEMPLATE_LINES_RECEIVED]: onOrderTemplateLinesReceived,
  [ORDER_TEMPLATE_PRODUCTS_RECEIVED]: onProductsReceived,
  [ORDER_TEMPLATE_CREATE_FAILED]: onOrderTemplateReceivedErrorMessage,
  [PAST_ORDERS_RECEIVED]: onPastOrderLinesReceived,
  [ORDER_TEMPLATE_RESET]: onOrderTemplateReset,
  [ORDER_TEMPLATE_EDIT_LINES_UPDATED]: onOrderTemplateEditLinesUpdated,
  [ORDER_TEMPLATE_EDIT_SEARCHED_LINES_RECEIVED]: onOrderTemplateSearchedLines,
});

//3.12.Editable order templates
function onOrderTemplateReceivedErrorMessage(state: State, action: OrderTemplateReceivedErrorMessageAction) {
  return {
    ...state,
    error: {
      ...action.payload,
    },
  };
}

//3.12.Editable order templates
function onProductsReceived(state: State, action: OrderTemplateReceiveProductsAction) {
  const productList = action.payload.products == null ? [] : action.payload.products;
  return {
    ...state,
    products: { ...state.products, ...arrayToObject(productList, (product: Product) => product.id) },
  };
}

function onOrderTemplatesAdded(state: State, action: OrderTemplatesAddedAction) {
  return {
    ...state,
    status: {
      type: UpdateType.Added as const,
      countAdded: action.payload,
    },
  };
}

function onOrderTemplatesRemoved(state: State, action: OrderTemplatesRemovedAction) {
  const status = { type: UpdateType.Removed as const };
  if (!state.items)
    return { ...state, status };

  return {
    ...state,
    items: state.items.filter(t => !action.payload.includes(t.id)),
    status,
  };
}

function onOrderTemplatesReceived(state: State, action: OrderTemplatesReceivedAction) {
  return {
    ...state,
    items: action.payload,
  };
}

function onOrderTemplateLinesReceived(state: State, action: OrderTemplatesLinesReceivedAction) {
  if (!state.items)
    return state;

  const { id: templateId, orderTemplateLines } = action.payload;

  return {
    ...state,
    items: state.items.map(template => {
      if (template.id !== templateId)
        return template;

      return { ...template, lines: orderTemplateLines };
    }),
  };
}

function onOrderTemplateReset(state: State, action: ordetTemplateResetAction) {
  return {
    ...state,
    resetUpdatedTemplate: action.payload,
  };
}

function onPastOrderLinesReceived(state: State, action: PastOrderRecievedAction) {
  const { pastOrders, productId } = action.payload;
  return {
    ...state,
    pastOrders: {
      ...(state.pastOrders || {}),
      [productId]: pastOrders,
    },
  };
}

function onOrderTemplateEditLinesUpdated(state: State, action: EditOrderTemplateLinesUpdatedAction) {
  return {
    ...state,
    modifiedDate: action.payload,
  };
}

function onOrderTemplateSearchedLines(state: State, action: EditOrderTemplateLinesSearchReceivedAction) {

  const { keyword, lines } = action.payload;
  const orderTemplate = state.orderTemplate;

  if (orderTemplate) {
    orderTemplate.lines = lines;
  }

  return {
    ...state,
    orderTemplate,
    linesSearched: Boolean(keyword),
  };
}
