import htmlPackage from 'components/objects/htmlBlock/package';
import headingPackage from 'components/objects/headingBlock/package';
import uspPackage from 'components/objects/usp/package';
import imageTilesPackage from 'components/objects/imageTiles/package';
import vdColumnTemplatePackage from 'components/visualDesigner/package';
import childMenuItemsPackage from 'components/objects/childMenuItems/package';
import productSetPackage from 'components/objects/productSet/package';
import imagePackage from 'components/objects/imageBlock/package';
import searchBarPackage from 'components/objects/searchBarBlock/package';
import spacerPackage from 'components/objects/spacerBlock/package';
import logoPackage from 'components/objects/logo/package';
import languageSelectorPackage from 'components/objects/languageSelector/package';
import basketSummaryPackage from 'components/objects/basket/summary/package';
import navigationPackage from 'components/objects/navigation/package';
import accountMenuPackage from 'components/objects/accountMenu/package';
import productSuggestionsPackage from 'components/objects/productSuggestions/package';
import callUsMessagePackage from 'components/objects/callUsMessage/package';
import copyrightTextPackage from 'components/objects/copyrightText/package';
import newsletterSubscriptionPackage from 'components/objects/newsletter/package';
import backToOverviewPackage from 'components/objects/backToOverviewBlock/package';
import breadcrumbPackage from 'components/objects/breadcrumbBlock/package';
import productPackage from 'components/objects/product/blocks/package';
import addThisPackage from 'components/objects/addThisBlock/package';
import lastViewedProductsPackage from 'components/objects/lastViewedProducts/package';
import sanaTextPackage from 'components/objects/sanaTextBlock/package';
import pageTitlePackage from 'components/objects/pageTitleBlock/package';

const defaultComponents = {
  ...htmlPackage,
  ...headingPackage,
  ...uspPackage,
  ...imageTilesPackage,
  ...vdColumnTemplatePackage,
  ...childMenuItemsPackage,
  ...productSetPackage,
  ...imagePackage,
  ...searchBarPackage,
  ...spacerPackage,
  ...logoPackage,
  ...languageSelectorPackage,
  ...basketSummaryPackage,
  ...navigationPackage,
  ...accountMenuPackage,
  ...productSuggestionsPackage,
  ...callUsMessagePackage,
  ...copyrightTextPackage,
  ...newsletterSubscriptionPackage,
  ...backToOverviewPackage,
  ...breadcrumbPackage,
  ...productPackage,
  ...addThisPackage,
  ...lastViewedProductsPackage,
  ...sanaTextPackage,
  ...pageTitlePackage,
};

export default defaultComponents;
