import styles from './Basket.module.scss';
import PropTypes from 'prop-types';
import { memo } from 'react';
import { SimpleText } from 'components/sanaText';
import { Placeholder } from 'components/primitives/placeholders';

const BasketTitle = ({ isPriceRequestPage }) => (
  <h1 className={styles.title}>
    {
      isPriceRequestPage ?
        <SimpleText textKey="PriceRequest_Header" placeholder={<Placeholder className={styles.titlePlaceholder} />} /> :
        <SimpleText textKey="ShoppingBasket_Header" placeholder={<Placeholder className={styles.titlePlaceholder} />} />
    }  
  </h1>
);

BasketTitle.propTypes = {
  isPriceRequestPage: PropTypes.bool,
};

export default memo(BasketTitle);